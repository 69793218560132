import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { configs } from "@/configs";
import { refetchQueries } from "@/utils";
import { deleteBankFee } from "@/services/bankFeeService";

import type { AxiosErrorWithData } from "@/client/api";

export type RemoveBankFeeDialogProps = {
  open: boolean;
  onClose: () => void;
  id: string;
  fetchKeys?: string[];
};

export function RemoveBankFeeDialog({
  open: isOpen,
  onClose,
  id,
  fetchKeys = [],
}: RemoveBankFeeDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: remove, isLoading } = useMutation(deleteBankFee, {
    onSuccess: async () => {
      enqueueSnackbar("ลบ สำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      onClose();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onConfirm = () => remove(+id);

  return (
    <ConfirmDialog
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      title="คุณต้องการลบหรือไม่"
      confirmMessage="ลบ"
      loading={isLoading}
    />
  );
}
