import { api } from "@/client/api";
import { configs } from "@/configs";

import type {
  BanMemberInput,
  Booking,
  EditMembershipInput,
  GetMemberBookingInput,
  Member,
  MemberBooking,
  MemberInput,
  Membership,
  PurchaseMembershipInput,
  PurchasePTInput,
  UpdateMemberInput,
  VerifyMemberInput,
} from "@/models";
import { parseDateTimeFromPrisma } from "@/utils";
import { GetMemberClassBookingInput } from "@/models";
import { GetTrainings } from "./training";

type GetMembers = {
  data: Member[];
  total: number;
};

type UpdateMember = {
  id: number;
  data: UpdateMemberInput;
};

type PurchaseMembership = {
  id: number;
  data: PurchaseMembershipInput;
};

type EditMembership = {
  id: number;
  memberId: number;
  data: EditMembershipInput;
};

type PurchasePT = {
  id: number;
  data: PurchasePTInput;
};

type GetMemberBookings = {
  data: Booking[];
  total: number;
};

type GetMemberClassBookings = {
  data: MemberBooking[];
  total: number;
};

type CreateMember = {
  isShow: boolean;
  memberLeft: number;
};

type GetMemberTrainingsInput = {
  query?: string;
  memberId: string;
};

type EditMemberPrtmissionGroup = {
  memberId: number;
  data: { permissionGroupId: number };
};

export async function getMembers(query = "") {
  const { data } = await api.get<GetMembers>(
    `${configs.apiUrl}/members?${query}`
  );
  return data;
}

export async function checkCreate() {
  const { data } = await api.get<CreateMember>(
    `${configs.apiUrl}/members/check-create`
  );
  return data;
}

export async function getMembersBySale(id: string, query = "") {
  const { data } = await api.get<GetMembers>(
    `${configs.apiUrl}/members/${id}/sale?${query}`
  );
  return data;
}

export async function getMember(id: number) {
  const { data } = await api.get<Member>(`${configs.apiUrl}/members/${id}`);
  return parseDateTimeFromPrisma(data) as Member;
}

export async function getMemberCode() {
  const { data } = await api.get<string>(`${configs.apiUrl}/members/code`);
  return data;
}

export async function createMember(body: MemberInput) {
  const { data } = await api.post<CreateMember>(
    `${configs.apiUrl}/members`,
    body
  );
  return data;
}

export async function updateMember({ id, data: body }: UpdateMember) {
  const { data } = await api.patch<MemberInput>(
    `${configs.apiUrl}/members/${id}`,
    body
  );
  return data;
}

export async function banMember({ id, data: body }: BanMemberInput) {
  const { data } = await api.patch<MemberInput>(
    `${configs.apiUrl}/members/${id}/ban`,
    body
  );
  return data;
}

export async function verify({ id, data: body }: VerifyMemberInput) {
  const { data } = await api.patch<MemberInput>(
    `${configs.apiUrl}/members/${id}/verify`,
    body
  );
  return data;
}

export async function purchaseMembership({
  id,
  data: body,
}: PurchaseMembership) {
  const { data } = await api.post<Membership>(
    `${configs.apiUrl}/members/${id}/memberships/purchase`,
    body
  );
  return data;
}

export async function purchasePT({ id, data: body }: PurchasePT) {
  const { data } = await api.post<Membership>(
    `${configs.apiUrl}/members/${id}/pts/purchase`,
    body
  );
  return data;
}

export async function getMemberBookings({
  memberId,
  type,
  query = "",
}: GetMemberBookingInput) {
  const response = await api.get(
    `${configs.apiUrl}/members/${memberId}/bookings?${query}&type=${type}`
  );

  return parseDateTimeFromPrisma(response.data) as GetMemberBookings;
}

export async function getMemberClassBookings({
  memberId,
  query = "",
}: GetMemberClassBookingInput) {
  const response = await api.get(
    `${configs.apiUrl}/members/${memberId}/bookings/class?${query}`
  );

  return parseDateTimeFromPrisma(response.data) as GetMemberClassBookings;
}

export async function getMemberTodayBookings(memberId: number) {
  const response = await api.get(
    `${configs.apiUrl}/members/${memberId}/bookings/today`
  );

  return parseDateTimeFromPrisma(response.data) as Booking[];
}

export async function editMembership({
  memberId,
  id,
  data: body,
}: EditMembership) {
  const { data } = await api.patch<Membership>(
    `${configs.apiUrl}/members/${memberId}/memberships/${id}`,
    body
  );
  return data;
}

export async function editPT({ memberId, id, data: body }: EditMembership) {
  const { data } = await api.patch<Membership>(
    `${configs.apiUrl}/members/${memberId}/pts/${id}`,
    body
  );
  return data;
}

export async function getMemberTrainings({
  memberId,
  query = "",
}: GetMemberTrainingsInput) {
  const { data } = await api.get<GetTrainings>(
    `${configs.apiUrl}/members/${memberId}/trainings?${query}`
  );
  return parseDateTimeFromPrisma(data) as GetTrainings;
}

export async function editMemberPermissionGroup({
  memberId,
  data: body,
}: EditMemberPrtmissionGroup) {
  await api.patch(
    `${configs.apiUrl}/members/${memberId}/permission-group`,
    body
  );
}

export async function getMemberQRCodePDF(id: string | number): Promise<string> {
  const response = await api.get<string>(
    `${configs.apiUrl}/members/${id}/member-qr-code-pdf`
  );

  return response.data;
}
