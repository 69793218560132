import { FileUpload, PhotoCamera } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useSearchParams } from "react-router-dom";

type BrowseImageOptionMenuProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
};

export function BrowseImageOptionMenu({
  anchorEl,
  onClose,
}: BrowseImageOptionMenuProps) {
  const open = Boolean(anchorEl);
  const [searchParams, setSearchParams] = useSearchParams();

  const openBrowseImageDialog = () => {
    searchParams.set("dialog", "browse-file");
    setSearchParams(searchParams);
    onClose();
  };

  const openTakePhotoDialog = () => {
    searchParams.set("dialog", "take-photo");
    setSearchParams(searchParams);
    onClose();
  };

  return (
    <Menu
      id="browse-option-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <MenuItem onClick={openBrowseImageDialog}>
        <ListItemIcon>
          <FileUpload />
        </ListItemIcon>
        <ListItemText>อัพโหลดจากคอมพิวเตอร์</ListItemText>
      </MenuItem>
      <MenuItem onClick={openTakePhotoDialog}>
        <ListItemIcon>
          <PhotoCamera />
        </ListItemIcon>
        <ListItemText>เลือกจากกล้องถ่ายภาพ</ListItemText>
      </MenuItem>
    </Menu>
  );
}
