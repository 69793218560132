import { Box } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { AddPhotoIcon } from "../Icon/AddPhotoIcon";
import { BrowseImageOptionMenu } from "./BrowseImageOptionMenu";
import { TakePhotoDialog } from "./TakePhotoDialog";
import { AddBrowseImageDialog } from "./AddBrowseImageDialog";

type AddImageThumbnailProps = {
  profile: {
    id: number;
    avatarUrl: string | null;
  };
  fetchKey?: string;
};

export function ProfileImageEditor({
  profile,
  fetchKey,
}: AddImageThumbnailProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dialog = searchParams.get("dialog");

  const onCloseDialog = () => {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  };

  const takePhotoDialog = {
    open: dialog === "take-photo",
    onClose: onCloseDialog,
    id: profile.id,
    fetchKey,
  };

  const browseImageDialog = {
    open: dialog === "browse-file",
    onClose: onCloseDialog,
    id: profile.id,
    module: "profiles" as const,
    fetchKey,
  };

  return (
    <Box>
      <Box
        component={"button"}
        height={300}
        width={300}
        borderRadius={1}
        border={0}
        p={0}
        sx={{
          backgroundColor: "#E9EEF1",
          cursor: "pointer",
          "&:hover": {
            opacity: 0.8,
          },
        }}
        onClick={handleClick}
      >
        {profile.avatarUrl ? (
          <Box
            component="img"
            width={300}
            height={300}
            borderRadius={1}
            src={profile.avatarUrl}
          />
        ) : (
          <AddPhotoIcon />
        )}
      </Box>
      <BrowseImageOptionMenu anchorEl={anchorEl} onClose={handleClose} />
      <TakePhotoDialog {...takePhotoDialog} />
      <AddBrowseImageDialog {...browseImageDialog} />
    </Box>
  );
}
