import {
  Avatar,
  Box,
  Drawer,
  List,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Menu,
  MenuItem,
  ListItemButton,
  Collapse,
  Tooltip,
} from "@mui/material";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import CampaignIcon from "@mui/icons-material/Campaign";
import PeopleIcon from "@mui/icons-material/People";
import PieChartIcon from "@mui/icons-material/PieChart";
import CircleIcon from "@mui/icons-material/Circle";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BuildIcon from "@mui/icons-material/Build";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import DescriptionIcon from "@mui/icons-material/Description";
import { useEffect, useState } from "react";
import {
  Link,
  Outlet,
  useResolvedPath,
  useMatch,
  useNavigate,
} from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { useAtom } from "jotai";

import { selectedBranchAtom } from "@/atom/global";
import { FormSelect } from "@/components/Select";
import {
  signOut,
  useAuthentication,
  useCurrentUser,
} from "@/features/authentication/contexts/AuthenticationContext";
import { configs } from "@/configs";
import { FitUPHorizontalLogo } from "@/components/FitUPHorizontalLogo";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { getFitnessExpired } from "@/services/fitness";

import { FacilityIcon, GoalIcon } from "./Icon";

import type { ListItemButtonProps } from "@mui/material";
import type { LinkProps } from "react-router-dom";

const drawerWidth = 260;

export function MainLayout() {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const match = useMatch({ path: `dashboard/*` });
  const isDashboard = match?.pathnameBase === "/dashboard";
  const [dashboardMenuOpen, setDashboardMenuOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useAtom(selectedBranchAtom);
  const { control } = useForm<{ id: number }>({
    defaultValues: selectedBranch ? { id: selectedBranch } : user.branches[0],
  });

  const { data } = useQuery(["check-expired"], () => getFitnessExpired());

  const id = useWatch({ name: "id", control });

  const showExpired = data
    ? data.expiredAt.diffNow(["days", "hours"]).days <= 30
    : false;

  useEffect(() => {
    setSelectedBranch(id);
  }, [id, setSelectedBranch]);

  const handleClick = () => setDashboardMenuOpen(!dashboardMenuOpen);

  const menu = [
    { label: "เช็คอิน", to: "/check-in", icon: <WhereToVoteIcon /> },
    { label: "คลาส", to: "/classes/calendar", icon: <FitnessCenterIcon /> },
    { label: "เทรนเนอร์", to: "/trainers", icon: <MilitaryTechIcon /> },
    { label: "เป้าหมายการเทรน", to: "/trainings", icon: <GoalIcon /> },
    { label: "ฝ่ายขาย", to: "/sales", icon: <BusinessCenterIcon /> },
    { label: "สมาชิก", to: "/members", icon: <PeopleIcon /> },
    {
      label: "ประเภทกิจกรรม",
      to: "/categories",
      icon: <VideoLibraryIcon />,
    },
    {
      label: "แพ็กเกจและสินค้า",
      to: "/products-and-packages",
      icon: <CampaignIcon />,
    },
    { label: "ใบเสร็จ", to: "/receipts", icon: <DescriptionIcon /> },
    { label: "พนักงาน", to: "/staffs", icon: <AdminPanelSettingsIcon /> },
    { label: "สิ่งอำนวยความสะดวก", to: "/facilities", icon: <FacilityIcon /> },
    { label: "จัดการฟิตเนส", to: "/fitness", icon: <BuildIcon /> },
  ];

  const [, dispatch] = useAuthentication();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const open = Boolean(anchorEl);
  const onClickAvatar: ListItemButtonProps["onClick"] = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickSignOut = () => {
    setAnchorEl(null);
    setOpenConfirmDialog(true);
  };
  const onClose = () => setAnchorEl(null);

  const confirmDialog = {
    maxWidth: "xs" as const,
    open: openConfirmDialog,
    onClose: () => setOpenConfirmDialog(false),
    onConfirm: async () => {
      await signOut(dispatch, queryClient);
      // Clear selected branches id after signout
      setSelectedBranch(null);
      localStorage.removeItem("selectedBranch");
    },
    title: "คุณต้องการออกจากระบบหรือไม่",
    confirmMessage: "ออกจากระบบ",
  };

  return (
    <Stack>
      {showExpired && (
        <Box
          height="56px"
          width="100%"
          sx={{
            backgroundColor: "error.main",
            color: "background.paper",
            zIndex: 1300,
            position: "fixed",
          }}
          textAlign="center"
          alignItems="center"
        >
          <Typography variant="body1" mt={2}>
            เนื่องจากแอคเคาท์ของคุณใกล้จะถึงกำหนดชำระค่าบริการ{" "}
            <a
              href={configs.aboutUsUrl}
              target="_blank"
              style={{ color: "white" }}
              rel="noreferrer"
            >
              กรุณาติดต่อทีมงาน FitUP
            </a>{" "}
            เพื่อทำการต่ออายุการใช้บริการ
          </Typography>
        </Box>
      )}

      <Box display="grid" gridTemplateColumns="260px 1fr" height="100%">
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              height: "100vh",
            },
          }}
          PaperProps={{ elevation: 2 }}
          variant="permanent"
          anchor="left"
        >
          <Stack
            height="100vh"
            py={2}
            gap={2}
            overflow="hidden"
            mt={showExpired ? 7 : 0}
          >
            <Box component={Link} to="/">
              <FitUPHorizontalLogo
                ml={2.5}
                sx={{ flexShrink: 0 }}
                onClick={() => navigate("/")}
              />
            </Box>
            <Stack px={2} width="100%">
              <FormSelect label="สาขา" name="id" control={control}>
                {user.branches.map(({ id, name }) => (
                  <MenuItem
                    key={id}
                    value={id}
                    onClick={() => navigate("/check-in")}
                  >
                    {name}
                  </MenuItem>
                ))}
              </FormSelect>
            </Stack>
            <List
              component="nav"
              sx={{
                display: "flex",
                gap: 1,
                flexDirection: "column",
                overflow: "auto",
              }}
            >
              <ListItemButton disableRipple onClick={handleClick}>
                <ListItemIcon>
                  <PieChartIcon
                    sx={{
                      color: isDashboard ? "primary.main" : "text.disabled",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="แดชบอร์ด"
                  sx={{
                    color: isDashboard ? "text.primary" : "text.secondary",
                  }}
                />
                {dashboardMenuOpen ? (
                  <ExpandLess sx={{ color: "text.secondary" }} />
                ) : (
                  <ExpandMore sx={{ color: "text.secondary" }} />
                )}
              </ListItemButton>
              <Collapse
                in={dashboardMenuOpen}
                collapsedSize="auto"
                timeout="auto"
                unmountOnExit
              >
                <List disablePadding component="div" sx={{ mb: 2.5 }}>
                  <ListItemLink
                    label="สรุปข้อมูลคลาส"
                    to="/dashboard/class-summary"
                    icon={<CircleIcon sx={{ fontSize: 14, ml: 1.75 }} />}
                  />
                  <ListItemLink
                    label="สรุปข้อมูลเทรนเนอร์"
                    to="/dashboard/trainer-summary"
                    icon={<CircleIcon sx={{ fontSize: 14, ml: 1.75 }} />}
                  />
                  <ListItemLink
                    label="สรุปข้อมูลสมาชิก"
                    to="/dashboard/member-summary"
                    icon={<CircleIcon sx={{ fontSize: 14, ml: 1.75 }} />}
                  />
                  <ListItemLink
                    label="สรุปยอดขาย"
                    to="/dashboard/sales-all-summary"
                    icon={<CircleIcon sx={{ fontSize: 14, ml: 1.75 }} />}
                  />
                  <ListItemLink
                    label="วิเคราะห์การขาย"
                    to="/dashboard/sales-summary"
                    icon={<CircleIcon sx={{ fontSize: 14, ml: 1.75 }} />}
                  />
                  <ListItemLink
                    label="วิเคราะห์ยอดขาย"
                    to="/dashboard/opportunity-summary"
                    icon={<CircleIcon sx={{ fontSize: 14, ml: 1.75 }} />}
                  />
                </List>
              </Collapse>
              {menu.map(({ label, to, icon }) => (
                <ListItemLink key={label} label={label} to={to} icon={icon} />
              ))}
            </List>
            <List component="div" sx={{ flexShrink: "0" }}>
              <ListItemButton
                onClick={onClickAvatar}
                data-testid="profileMenuBtn"
              >
                <ListItemAvatar>
                  <Avatar alt={user.name} src={user.avatarURL} />
                </ListItemAvatar>
                <ListItemText primary={user.name} secondary={user.position} />
              </ListItemButton>
            </List>
            <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
              <MenuItem data-testid="signOutBtn" onClick={onClickSignOut}>
                ออกจากระบบ
              </MenuItem>
            </Menu>
            <Tooltip title={configs.buildNumber}>
              <Typography variant="caption" align="center">
                {configs.version}
              </Typography>
            </Tooltip>
          </Stack>
        </Drawer>
        <Box
          py={4}
          px={3}
          mx="auto"
          width="100%"
          maxWidth="1440px"
          mt={showExpired ? 7 : 0}
        >
          <Outlet />
        </Box>
        <ConfirmDialog {...confirmDialog} />
      </Box>
    </Stack>
  );
}

function ListItemLink({
  label,
  to,
  icon,
}: {
  label: string;
  icon: JSX.Element | null;
} & LinkProps) {
  const resolved = useResolvedPath(to);
  const path = RegExp(/classes\/.*/).test(resolved.pathname)
    ? "classes/*"
    : `${resolved.pathname}/*`;
  const match = useMatch({ path });

  return (
    <ListItemButton
      component={Link}
      disableRipple
      key={label}
      selected={!!match}
      to={to}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText sx={{ color: "text.secondary" }} primary={label} />
    </ListItemButton>
  );
}
