import { Card, CardContent, Stack, Typography } from "@mui/material";

import { useSalesChartByPaymentType } from "@/hooks/useDashBoard";
import { formatNumber } from "@/formatter";

import type { DateTime } from "luxon";

interface AllTotalPriceCardProps {
  startDate: DateTime;
  endDate: DateTime;
}

export function AllTotalPriceCard({
  startDate,
  endDate,
}: AllTotalPriceCardProps) {
  const { data } = useSalesChartByPaymentType(startDate, endDate);

  const total = data?.reduce((acc, item) => acc + item.value, 0) ?? 0;

  return (
    <Card>
      <CardContent sx={{ p: 2, paddingBottom: "16px !important" }}>
        <Stack gap={4}>
          <Typography variant="h6" textAlign="center">
            สรุปยอดขายทั้งหมด
          </Typography>
          <Stack
            direction="row"
            gap={2}
            width="100%"
            justifyContent="center"
            alignItems="center"
            p={1.5}
          >
            <Typography variant="h4">{formatNumber(total)}</Typography>
            <Typography variant="h5" sx={{ fontSize: 24 }}>
              บาท
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
