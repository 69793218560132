import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useController } from "react-hook-form";

import { usePaymentMethods } from "@/hooks/usePaymentMethod";

import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";
import type { RadioGroupProps } from "@mui/material";

export function PaymentMethodRadioGroup<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  onChange,
  label = "",
  ...props
}: Pick<ControllerProps<TFieldValues, TName>, "name" | "control"> &
  RadioGroupProps & { label?: string }) {
  const { data: paymentMethods, isFetching } = usePaymentMethods();

  const { field } = useController({ name, control });

  return !isFetching ? (
    <FormControl>
      <FormLabel id="payment-method-radio-group">{label}</FormLabel>
      <RadioGroup row value={field.value} onChange={field.onChange} {...props}>
        {paymentMethods?.map((paymentMethod) => (
          <FormControlLabel
            key={paymentMethod.code}
            value={paymentMethod.code}
            control={<Radio />}
            label={paymentMethod.name}
          />
        ))}
      </RadioGroup>
    </FormControl>
  ) : (
    <></>
  );
}
