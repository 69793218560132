import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import { configs } from "@/configs";
import { refetchQueries } from "@/utils";
import { updateBankFee } from "@/services/bankFeeService";

import { useBankFeeEditorForm } from "./BankFeeEditor";
import { BankFeeEditorDialog } from "./BankFeeEditorDialog";

import type { AxiosErrorWithData } from "@/client/api";
import type { BankFee } from "@/services/bankFeeService";

export type EditBankFeeDialogProps = {
  open: boolean;
  data?: BankFee;
  onClose: () => void;
  id: string;
  fetchKeys?: string[];
};

export function EditBankFeeDialog({
  open: isOpen,
  onClose,
  id,
  data,
  fetchKeys = [],
}: EditBankFeeDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useBankFeeEditorForm();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && data) {
      reset({
        bank: {
          id: data.bankId,
          name: data.bankName,
        },
        feeAmount: data.feeAmount,
      });
    }
  }, [isOpen, data, reset]);

  function close() {
    onClose();
  }

  const { mutate: update, isLoading } = useMutation(updateBankFee, {
    onSuccess: async () => {
      enqueueSnackbar("บันทึกค่าธรรมเนียมสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onSubmit = handleSubmit((form) => {
    const data = {
      id: +id,
      feeAmount: form.feeAmount,
    };

    update(data);
  });

  const title = "แก้ไขค่าธรรมเนียม";

  return (
    <BankFeeEditorDialog
      isEdit
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
