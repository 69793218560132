import { api } from "@/client/api";
import { configs } from "@/configs";

export type Banks = {
  id: number;
  name: string;
};

export async function getBanks() {
  const response = await api.get<Banks[]>(`${configs.apiUrl}/banks`);

  return response.data;
}
