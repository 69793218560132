import { Card, CardContent, Typography, Stack } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { useState } from "react";

import { useSalesChartByPaymentType } from "@/hooks/useDashBoard";
import { formatNumber } from "@/formatter";
import { CircularLoading } from "@/components/CircularLoading";
import { EmptyResultIcon } from "@/components/Icon";
import { PieCenterLabel } from "@/components/Chart/PieCenterLabel";

import type { DateTime } from "luxon";

interface SalesByPaymentTypeProps {
  startDate: DateTime;
  endDate: DateTime;
}

export function SalesByPaymentType({
  startDate,
  endDate,
}: SalesByPaymentTypeProps) {
  const [focusIndex, setFocusIndex] = useState<number>();
  const { data: paymentChannelsData, isFetching } = useSalesChartByPaymentType(
    startDate,
    endDate
  );

  const chartDate =
    paymentChannelsData?.map((item, index) => {
      return {
        id: index,
        value: item.value,
        label: item.name,
      };
    }) ?? [];

  const total = chartDate.reduce((acc, item) => acc + item.value, 0);

  const showItem =
    focusIndex !== undefined
      ? chartDate[focusIndex]
      : { label: "ทั้งหมด", value: total };

  const isEmpty = total === 0;

  return (
    <Card>
      <CardContent>
        <Stack gap={5} mb={3}>
          <Typography variant="subtitle1">
            ยอดขายแบ่งตามประเภทการชำระเงิน
          </Typography>
          {isFetching ? (
            <CircularLoading height={360} />
          ) : isEmpty ? (
            <EmptyResultIcon height={360} />
          ) : (
            <PieChart
              series={[{ data: chartDate, innerRadius: 120 }]}
              height={360}
              onHighlightChange={(value) => setFocusIndex(value?.dataIndex)}
            >
              <PieCenterLabel heightOffset={-48}>
                {showItem.label}
              </PieCenterLabel>
              <PieCenterLabel heightOffset={0} fontSize={34}>{`${formatNumber(
                showItem.value
              )}`}</PieCenterLabel>
              <PieCenterLabel heightOffset={36}>บาท</PieCenterLabel>
            </PieChart>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
