import { BankFeeEditor } from "./BankFeeEditor";
import { FormConfirmDialog } from "@/components/FormConfirmDialog";

import type { Control } from "react-hook-form";
import type { BankFeeEditorState } from "./BankFeeEditor";

export function BankFeeEditorDialog({
  open,
  title,
  onClose,
  onSubmit,
  loading,
  control,
  isEdit = false,
}: {
  open: boolean;
  title: string;
  onClose: () => void;
  onSubmit: () => void | PromiseLike<void>;
  loading: boolean;
  control: Control<BankFeeEditorState>;
  isEdit?: boolean;
}) {
  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={loading}
      open={open}
      onClose={onClose}
      onConfirm={onSubmit}
    >
      <BankFeeEditor isEdit={isEdit} control={control} />
    </FormConfirmDialog>
  );
}
