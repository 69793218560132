import { Card, CardContent, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";

import { useSalesChartByMembership } from "@/hooks/useDashBoard";
import { formatAbbreviateNumber } from "@/formatter";
import { configs } from "@/configs";

import type { DateTime } from "luxon";
import type {
  AxisConfig,
  AxisScaleConfig,
  ChartsXAxisProps,
  MakeOptional,
} from "@mui/x-charts/internals";

interface SalesByMembershipProps {
  startDate: DateTime;
  endDate: DateTime;
}

export function SalesByMembership({
  startDate,
  endDate,
}: SalesByMembershipProps) {
  const { data, isFetching } = useSalesChartByMembership(startDate, endDate);

  const items =
    data?.map((item) => ({ name: item.name, value: item.value })) ?? [];

  const tickLabelStyle =
    items.length >= configs.adjustXAxisLabelBarChart
      ? {
          angle: 17,
          textAnchor: "start" as const,
          fontSize: 12,
        }
      : {};

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">
          ยอดขายแบ่งตาม Membership สูงสุด 12 รายการ
        </Typography>

        <BarChart
          loading={isFetching}
          dataset={items}
          margin={{ bottom: 64 }}
          yAxis={[
            {
              label: "ยอดขาย (บาท)",
              valueFormatter: (value: number) => formatAbbreviateNumber(value),
            },
          ]}
          xAxis={[
            {
              scaleType: "band",
              dataKey: "name",
              categoryGapRatio: 0.6,
              tickLabelStyle,
            } as unknown as MakeOptional<
              AxisConfig<keyof AxisScaleConfig, any, ChartsXAxisProps>,
              "id"
            >, // https://github.com/mui/mui-x/issues/10334,
          ]}
          series={[{ dataKey: "value", color: "#03A9F4" }]}
          height={350}
        />
      </CardContent>
    </Card>
  );
}
