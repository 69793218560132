import { Autocomplete, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import * as yup from "yup";

import { useBanks } from "@/hooks/useBanks";

import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";
import type { TextFieldProps } from "@mui/material";
import type { AutocompleteProps as MuiAutocompleteProps } from "@mui/material/Autocomplete/Autocomplete";

export type BankAutocompleteValue = {
  id: number;
  name: string;
};

export type BankAutocompleteProps = {
  label?: string;
  TextFieldProps?: Partial<TextFieldProps>;
} & Omit<
  MuiAutocompleteProps<BankAutocompleteValue, false, false, false>,
  "renderInput" | "options"
>;

export function BankAutocomplete({
  label,
  TextFieldProps,
  ...props
}: BankAutocompleteProps) {
  const { data: raw, isFetching } = useBanks();
  const banks = raw ?? [];

  const options = banks.map(({ id, name }) => ({
    id,
    name,
  }));

  return (
    <Autocomplete
      options={options}
      loading={isFetching}
      getOptionLabel={({ name }) => name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField label={label} {...params} {...TextFieldProps} />
      )}
      {...props}
    />
  );
}

export function FormBankAutocomplete<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  required = false,
  ...props
}: { required?: boolean } & Pick<
  ControllerProps<TFieldValues, TName>,
  "name" | "control"
> &
  Omit<
    BankAutocompleteProps,
    "value" | "name" | "TextFieldProps" | "onChange"
  >) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <BankAutocomplete
      label="ชื่อธนาคาร/สถาบันการเงิน"
      value={field.value ?? null}
      onChange={(_, newValue) => field.onChange(newValue)}
      TextFieldProps={{
        inputRef: field.ref,
        onBlur: field.onBlur,
        error: fieldState.invalid,
        helperText: fieldState.error?.message,
        required,
      }}
      {...props}
    />
  );
}

const schema = yup
  .object({
    id: yup.number().integer().required(),
    name: yup.string().required(),
  })
  .label("ชื่อธนาคาร/สถาบันการเงิน")
  .default(null)
  .nullable();

BankAutocomplete.schema = schema;
