import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth } from "@/utils";

import type { MouseEventHandler } from "react";
import type { BankFee } from "@/services/bankFeeService";

const columnsWidth = [768, 220, 96].map((item) => calculateColumnWidth(item));

export type BankFeeTableRowProps = {
  data: BankFee;
  fetchKeys?: string[];
};

export function BankFeeTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[1]}>ค่าธรรมเนียม</TableCell>
      <TableCell width={columnsWidth[2]} />
    </TableRow>
  );
}

export function BankFeeTableRow({ data, fetchKeys }: BankFeeTableRowProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { id, bankName, feeAmount } = data;

  const idString = id.toString();

  const onClickRemove: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "bank-fee-remove");
    searchParams.set("id", idString);
    setSearchParams(searchParams);
  };

  const onClickEdit: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "bank-fee-edit");
    searchParams.set("id", idString);
    setSearchParams(searchParams);
  };

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell>
        <TextEllipsis variant="body2" line={2}>
          {bankName}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <Typography fontWeight={600}>{feeAmount.toFixed(4)}%</Typography>
      </TableCell>
      <TableCell sx={{ px: 0 }}>
        <Stack direction="row" gap={1}>
          <Tooltip title="แก้ไข">
            <IconButton onClick={onClickEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="ลบ">
            <IconButton onClick={onClickRemove}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
}
