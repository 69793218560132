import { api } from "@/client/api";
import { configs } from "@/configs";

import type {
  UpdateAvatarProfileInput,
  Profile,
  UpdateAvatarImageInput,
} from "@/models";

export async function updateAvatar({
  id,
  data: body,
}: UpdateAvatarProfileInput) {
  const { data } = await api.patch<Profile>(
    `${configs.apiUrl}/profiles/avatar/${id}`,
    body
  );
  return data;
}

export async function updateAvatarByFile({
  id,
  data: body,
}: UpdateAvatarImageInput) {
  const { data } = await api.patch<Profile>(
    `${configs.apiUrl}/profiles/avatar/${id}/image`,
    body
  );
  return data;
}
