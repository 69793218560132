import { configs } from "@/configs";
import { api } from "@/client/api";
import { parseURLSearchParams } from "@/utils";

import type { DateTime } from "luxon";
import type {
  MemberGoalCountSummary,
  SummarizeTrainingSummary,
  TrainerTrainingSummary,
  TrainingGoal,
} from "@/models";

type DateTimeRangeInput = {
  startedAt: DateTime;
  endedAt: DateTime;
};

type GetTrainerTrainingSummaryInput = {
  page: number;
  goal?: TrainingGoal;
} & DateTimeRangeInput;

type GetSummarizeTrainingSummaryInput = {
  page: number;
} & DateTimeRangeInput;

export interface Column {
  id:
    | "date"
    | "time"
    | "memberCode"
    | "memberName"
    | "productName"
    | "subId"
    | "unitPrice"
    | "quantity"
    | "totalPrice"
    | "discount"
    | "tax"
    | "netTotal"
    | "paymentType"
    | "bankFee"
    | "sellerCode"
    | "sellerName"
    | "sellerRole"
    | "invoiceNo"
    | "remarks";
  label: string;
  minWidth: number;
  align?: "right";
  format?: (value: number) => string;
}

interface RowData {
  [key: string]: string | number;
}

export interface SalesSummaryData {
  data: RowData[];
  total: number;
}

export async function getDashboardUrl(type = "") {
  const { data } = await api.get<{
    data: string;
  }>(`${configs.apiUrl}/dashboard/${type}`);
  return data;
}

export async function getMemberGoalCountSummary({
  startedAt,
  endedAt,
}: DateTimeRangeInput) {
  const params = parseURLSearchParams({
    startedAt: startedAt.toISO(),
    endedAt: endedAt.toISO(),
  });

  const { data } = await api.get<MemberGoalCountSummary>(
    `${configs.apiUrl}/dashboard/trainings/member-goal-count-summary?${params}`
  );

  return data;
}

export async function getTrainerTrainingSummary({
  startedAt,
  endedAt,
  goal,
  page,
}: GetTrainerTrainingSummaryInput) {
  const input = {
    startedAt: startedAt.toISO(),
    endedAt: endedAt.toISO(),
    page: page.toString(),
    limit: "10",
  } as Record<string, string>;

  if (goal) {
    input["goal"] = goal;
  }

  const params = parseURLSearchParams(input);

  const { data } = await api.get<TrainerTrainingSummary>(
    `${configs.apiUrl}/dashboard/trainings/trainer-training-summary?${params}`
  );

  return data;
}

export async function getSumarizeTrainingSummary({
  startedAt,
  endedAt,
  page,
}: GetSummarizeTrainingSummaryInput) {
  const params = parseURLSearchParams({
    startedAt: startedAt.toISO(),
    endedAt: endedAt.toISO(),
    page: page.toString(),
    limit: "10",
  });

  const { data } = await api.get<SummarizeTrainingSummary>(
    `${configs.apiUrl}/dashboard/trainings/summarize-training-summary?${params}`
  );

  return data;
}

export type getDashboardForSalesAllSummaryCsvInput = {
  startedAt?: DateTime;
  endedAt?: DateTime;
};

export type getDashboardForSalesAllSummaryInput = {
  startedAt?: DateTime;
  endedAt?: DateTime;
  limit: number;
  page: number;
};

export type ChartDataPoint = {
  name: string;
  value: number;
};

export type SaleChartByTopStart = {
  name: string;
  membership: number;
  personalTraining: number;
  others: number;
  total: number;
};

export async function getDashboardForSalesAllSummaryCsv({
  startedAt,
  endedAt,
}: getDashboardForSalesAllSummaryCsvInput) {
  const paramsObj: Record<string, string> = {};

  if (startedAt) {
    paramsObj.startedAt = startedAt.toISO();
  }

  if (endedAt) {
    paramsObj.endedAt = endedAt.toISO();
  }

  const params = new URLSearchParams(paramsObj).toString();

  return await api.get<Blob>(
    `${configs.apiUrl}/dashboard/sales-summaries/export?${params}`,
    {
      responseType: "blob",
    }
  );
}

export async function getDashboardForSalesAllSummary({
  startedAt,
  endedAt,
  limit = 50,
  page = 1,
}: getDashboardForSalesAllSummaryInput) {
  const paramsObj: Record<string, string> = {
    limit: limit.toString(),
    page: page.toString(),
  };

  if (startedAt) {
    paramsObj.startedAt = startedAt.toISO();
  }

  if (endedAt) {
    paramsObj.endedAt = endedAt.toISO();
  }

  const params = new URLSearchParams(paramsObj).toString();

  const response = await api.get<SalesSummaryData>(
    `${configs.apiUrl}/dashboard/sales-summaries?${params}`
  );

  return response.data;
}

export async function getSalesChartByPaymentType(
  startedAt: DateTime,
  endedAt: DateTime
) {
  const params = new URLSearchParams({
    startedAt: startedAt.toISO(),
    endedAt: endedAt.toISO(),
  }).toString();

  const { data } = await api.get<ChartDataPoint[]>(
    `${configs.apiUrl}/dashboard/sales-summaries/payment-type?${params}`
  );

  return data;
}

export async function getSalesChartByInstallment(
  startedAt: DateTime,
  endedAt: DateTime
) {
  const params = new URLSearchParams({
    startedAt: startedAt.toISO(),
    endedAt: endedAt.toISO(),
  }).toString();

  const { data } = await api.get<ChartDataPoint[]>(
    `${configs.apiUrl}/dashboard/sales-summaries/installment?${params}`
  );

  return data;
}

export async function getSalesChartByTopStaff(
  startedAt: DateTime,
  endedAt: DateTime
) {
  const params = new URLSearchParams({
    startedAt: startedAt.toISO(),
    endedAt: endedAt.toISO(),
  }).toString();

  const { data } = await api.get<SaleChartByTopStart[]>(
    `${configs.apiUrl}/dashboard/sales-summaries/top-staff?${params}`
  );

  return data;
}

export async function getSalesChartByCategory(
  startedAt: DateTime,
  endedAt: DateTime
) {
  const params = new URLSearchParams({
    startedAt: startedAt.toISO(),
    endedAt: endedAt.toISO(),
  }).toString();

  const { data } = await api.get<ChartDataPoint[]>(
    `${configs.apiUrl}/dashboard/sales-summaries/category?${params}`
  );

  return data;
}

export async function getSalesChartByTopMembership(
  startedAt: DateTime,
  endedAt: DateTime
) {
  const params = new URLSearchParams({
    startedAt: startedAt.toISO(),
    endedAt: endedAt.toISO(),
  }).toString();

  const { data } = await api.get<ChartDataPoint[]>(
    `${configs.apiUrl}/dashboard/sales-summaries/top-membership?${params}`
  );

  return data;
}

export async function getSalesChartByTopPersonalTraining(
  startedAt: DateTime,
  endedAt: DateTime
) {
  const params = new URLSearchParams({
    startedAt: startedAt.toISO(),
    endedAt: endedAt.toISO(),
  }).toString();

  const { data } = await api.get<ChartDataPoint[]>(
    `${configs.apiUrl}/dashboard/sales-summaries/top-personal-training?${params}`
  );

  return data;
}

export async function getSalesChartByTopProduct(
  startedAt: DateTime,
  endedAt: DateTime
) {
  const params = new URLSearchParams({
    startedAt: startedAt.toISO(),
    endedAt: endedAt.toISO(),
  }).toString();

  const { data } = await api.get<ChartDataPoint[]>(
    `${configs.apiUrl}/dashboard/sales-summaries/top-product?${params}`
  );

  return data;
}
