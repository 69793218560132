import { Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { fromISO, now } from "@/lib/dateTime";
import { mapOptional } from "@/utils";

import { AllTotalPriceCard } from "./AllTotalPriceCard";
import { SalesByCategory } from "./SalesByCategory";
import { SalesByPaymentType } from "./SalesByPaymentType";
import { SalesByInstallment } from "./SalesByInstallment";
import { SalesByMembership } from "./SalesByMembership";
import { SalesByTrainer } from "./SalesByTrainer";
import { SalesByStaff } from "./SalesByStaff";
import { SalesByProduct } from "./SalesByProduct";

export function SalesAllSummaryDashboardPage() {
  const [searchParams] = useSearchParams();

  const startedAtISO = searchParams.get("startDate");
  const endedAtISO = searchParams.get("endDate");

  const startDate = mapOptional(startedAtISO, fromISO) ?? now().startOf("day");
  const endDate = mapOptional(endedAtISO, fromISO) ?? now().endOf("day");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AllTotalPriceCard startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <SalesByPaymentType startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <SalesByInstallment startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid item xs={12}>
        <SalesByStaff startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid item xs={12}>
        <SalesByCategory startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid item xs={12}>
        <SalesByMembership startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid item xs={12}>
        <SalesByTrainer startDate={startDate} endDate={endDate} />
      </Grid>
      <Grid item xs={12}>
        <SalesByProduct startDate={startDate} endDate={endDate} />
      </Grid>
    </Grid>
  );
}
