import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import * as yup from "yup";

import { configs } from "@/configs";
import { updateAvatar } from "@/services/profile";

import { FileForm, FormImageUploader } from "../ImageUploader";

import type { UploadModule } from "@/models";

export function AddBrowseImageDialog({
  open: isOpen,
  onClose,
  id,
  fetchKey,
  module,
}: {
  open: boolean;
  onClose: (reason: "success" | "error" | "cancel") => void;
  id: number;
  fetchKey?: string;
  module: UploadModule;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: update, isLoading } = useMutation(updateAvatar);
  const queryClient = useQueryClient();

  const schema = yup.object({
    images: FormImageUploader.schema,
  });

  const { control, reset, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      images: [] as FileForm[],
    },
  });

  useEffect(() => {
    if (!isOpen) {
      reset();
      return;
    }
  }, [isOpen, reset]);

  const onConfirm = () => {
    const value = getValues();

    if (value.images.length > 0) {
      update(
        {
          id,
          data: { avatarUrl: value.images[0].url },
        },
        {
          onSuccess: async () => {
            enqueueSnackbar("เพิ่มรูปภาพสำเร็จ", {
              variant: "success",
            });
            await queryClient.refetchQueries(fetchKey);
            reset();
            onClose("success");
          },
          onError: (error) => {
            console.error(error);
            enqueueSnackbar(configs.unknownErrorMessage, { variant: "error" });
            onClose("error");
          },
        }
      );
    } else {
      enqueueSnackbar("โปรดเลือกไฟล์ภาพที่จะอัพโหลด", { variant: "error" });
    }
  };

  const title = "เพิ่มรูปถ่าย";

  return (
    <Dialog open={isOpen} onClose={() => onClose("cancel")}>
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        {title}
        <Tooltip title="ปิด">
          <IconButton onClick={() => onClose("cancel")} sx={{ ml: "auto" }}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={{ overflow: "hidden" }}>
        <FormImageUploader
          control={control}
          name="images"
          module={module}
          max={1}
          aspect={configs.aspectRatio.avatar}
        />
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={2.5}>
          <Button
            color="inherit"
            variant="contained"
            onClick={() => onClose("cancel")}
          >
            ยกเลิก
          </Button>
          <LoadingButton
            loading={isLoading}
            color="primary"
            variant="contained"
            onClick={onConfirm}
          >
            บันทึก
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
