import {
  Card,
  CardContent,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import React from "react";

import {
  Column,
  getDashboardForSalesAllSummary,
  SalesSummaryData,
} from "@/services/dashboard";
import { fromISO, now } from "@/lib/dateTime";
import { mapOptional } from "@/utils";
import { configs } from "@/configs";
import { DataTableBody } from "@/components/DataTableBody";

const columns: readonly Column[] = [
  { id: "date", label: "วันที่", minWidth: 160 },
  { id: "time", label: "เวลา", minWidth: 100 },
  { id: "memberCode", label: "หมายเลขสมาชิก", minWidth: 160 },
  { id: "memberName", label: "ชื่อ-นามสกุล", minWidth: 200 },
  { id: "productName", label: "สินค้าหรือแพ็กเกจ", minWidth: 220 },
  { id: "subId", label: "รหัสสินค้าย่อย", minWidth: 160 },
  { id: "unitPrice", label: "ราคาต่อหน่วย(บาท)", minWidth: 160 },
  { id: "quantity", label: "จำนวน", minWidth: 100 },
  { id: "totalPrice", label: "ราคารวม(บาท)", minWidth: 160 },
  { id: "discount", label: "ส่วนลด(บาท)", minWidth: 160 },
  { id: "tax", label: "VAT", minWidth: 160 },
  { id: "netTotal", label: "ราคาสุทธิ(บาท)", minWidth: 160 },
  { id: "paymentType", label: "ประเภทการจ่ายเงิน", minWidth: 220 },
  { id: "bankFee", label: "ค่าธรรมเนียม", minWidth: 160 },
  { id: "sellerCode", label: "รหัสผู้ขาย", minWidth: 160 },
  { id: "sellerName", label: "ชื่อผู้ขาย", minWidth: 200 },
  { id: "sellerRole", label: "แผนก", minWidth: 160 },
  { id: "invoiceNo", label: "เลขที่ใบแจ้งหนี้", minWidth: 160 },
];

const tableWidth = columns.reduce((sum, { minWidth }) => sum + minWidth, 0);

export const SalesAllSummaryTablePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const startedAtISO = searchParams.get("startDate");
  const endedAtISO = searchParams.get("endDate");
  const page = Number(searchParams.get("page") ?? 1);
  const rowsPerPage = Number(
    searchParams.get("rowsPerPage") ?? configs.saleDashboardRowsPerPage
  );

  const startDate = mapOptional(startedAtISO, fromISO) ?? now().startOf("day");
  const endDate = mapOptional(endedAtISO, fromISO) ?? now().endOf("day");

  const { data: raw, isFetching } = useQuery<SalesSummaryData>(
    ["getSalesAllSummaryDataTable", startDate, endDate, rowsPerPage, page],
    () =>
      getDashboardForSalesAllSummary({
        startedAt: startDate ?? undefined,
        endedAt: endDate ?? undefined,
        limit: rowsPerPage,
        page,
      })
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    searchParams.set("page", (newPage + 1).toString());
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    searchParams.set("page", "1");
    searchParams.set("rowsPerPage", event.target.value);
    setSearchParams(searchParams);
  };

  const total = raw?.total ?? 0;
  const data = raw?.data ?? [];

  return (
    <Card>
      <CardContent sx={{ overflowX: "auto", width: 1132 }}>
        <TableContainer sx={{ maxHeight: 600, width: tableWidth }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <DataTableBody loading={isFetching} data={data}>
              {data.map((row, index) => (
                <TableRow hover tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        width={column.minWidth}
                      >
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </DataTableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ p: "16px !important" }}
      />
    </Card>
  );
};
