import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import QrCodeIcon from "@mui/icons-material/QrCode";

import { getApiErrorMessage, getMemberStatus } from "@/utils";
import { MemberStatus } from "@/models";
import { getMemberQRCodePDF } from "@/services/member";
import { ProfileImageEditor } from "@/components/ProfileImageEditor";

import { MemberStatusLabel } from "../../components/MemberStatusLabel";
import { MemberProfileDetail } from "../../components/MemberProfileDetail";
import { EditMemberDialog } from "./EditMemberDialog";
import { BanMemberDialog } from "../../components/BanMemberDialog";
import { MemberVerifyDialog } from "../../pages/MemberListPage/MemberVerifyDialog";

import type { Member } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

export type MemberProfileProps = {
  data: Member;
};

const QUERY_KEY = "member";

export function MemberProfile({ data }: MemberProfileProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: generateMemberQRCodePDF, isLoading: isPDFLoading } =
    useMutation(getMemberQRCodePDF, {
      onSuccess: (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
      },
    });

  const { id, code: memberCode, profile, accountId } = data;

  const memberStatus = getMemberStatus(data);
  const isMemberBanned = memberStatus === MemberStatus.Ban;

  const [searchParams, setSearchParams] = useSearchParams();
  const dialog = searchParams.get("dialog");

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  function openEditDialog() {
    searchParams.set("dialog", "edit");
    setSearchParams(searchParams);
  }

  function openBanDialog() {
    searchParams.set("dialog", "member-ban");
    setSearchParams(searchParams);
  }

  function openVerifyDialog() {
    searchParams.set("dialog", "member-verify");
    setSearchParams(searchParams);
  }

  function handleClickOpenPDF() {
    generateMemberQRCodePDF(data.id);
  }

  const editDialog = {
    open: dialog === "edit",
    onClose: onCloseDialog,
    id,
    data,
  };

  const verifyDialog = {
    title: "ยืนยันสมาชิก",
    open: dialog === "member-verify",
    id,
    onClose: onCloseDialog,
    data,
  };

  const banDialog = {
    open: data && dialog === "member-ban",
    onClose: onCloseDialog,
    data,
    fetchKey: QUERY_KEY,
  };

  const profileImageEditor = {
    profile,
    fetchKey: QUERY_KEY,
  };

  return (
    <Card>
      <Stack>
        <CardHeader
          title={
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="h6">{memberCode}</Typography>
              <MemberStatusLabel variant="subtitle1" status={memberStatus} />
            </Stack>
          }
          action={
            <Stack direction="row">
              <LoadingButton
                variant="contained"
                sx={{ mr: 2 }}
                onClick={handleClickOpenPDF}
                loading={isPDFLoading}
              >
                QR Member Card
              </LoadingButton>
              <Button variant="text" onClick={openBanDialog}>
                <Typography
                  variant="subtitle2"
                  color={isMemberBanned ? "info.main" : "error.main"}
                >
                  {isMemberBanned ? "ปลดระงับสมาชิก" : "ระงับสมาชิก"}
                </Typography>
              </Button>
              {!accountId && (
                <Tooltip title="ยืนยันสมาชิก">
                  <IconButton onClick={openVerifyDialog}>
                    <QrCodeIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="แก้ไขสมาชิก">
                <IconButton onClick={openEditDialog}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          }
        />
        <CardContent>
          <Stack gap={4}>
            <ProfileImageEditor {...profileImageEditor} />
            <MemberProfileDetail data={data} />
          </Stack>
          <EditMemberDialog {...editDialog} />
          <BanMemberDialog {...banDialog} />
          <MemberVerifyDialog {...verifyDialog} />
        </CardContent>
      </Stack>
    </Card>
  );
}
