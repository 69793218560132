import { useController, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Radio, FormControlLabel, Box, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import * as yup from "yup";

import { FormRadioGroup } from "@/components/FormRadioGroup";
import { FormNumericTextField } from "@/components/NumericTextField";
import { PaymentMethodCode } from "@/models";
import { yupDigitValidation } from "@/lib/yup/validation";

import {
  BankAutocomplete,
  FormBankAutocomplete,
} from "../../features/member/pages/BankAutocomplete";
import { PaymentMethodRadioGroup } from "./PaymentMethodRadioGroup";

import type { ControllerProps } from "react-hook-form";
import type { InferType } from "yup";

export type PaymentEditorState = InferType<typeof schema>;

export type PaymentEditorProps = Pick<
  ControllerProps<PaymentEditorState>,
  "control"
>;

export function PaymentEditor({ control }: PaymentEditorProps) {
  const paymentMethod = useWatch({ name: "paymentMethod", control });
  const isInstallment = useWatch({ name: "isInstallment", control }) === "true";

  const {
    field: { onChange: onChangeInstallmentMonths },
  } = useController({ control, name: "installmentMonths" });
  const {
    field: { onChange: onChangeInterestRate },
  } = useController({ control, name: "interestRate" });
  const {
    field: { onChange: onChangeBank },
  } = useController({ control, name: "bank" });
  const {
    field: { onChange: onChangeIsInstallment },
  } = useController({ control, name: "isInstallment" });

  const isCreditCard = paymentMethod === PaymentMethodCode.CreditCard;

  useEffect(() => {
    if (!isCreditCard) {
      onChangeBank(undefined);
      onChangeIsInstallment("false");
    }
  }, [isCreditCard, onChangeBank, onChangeIsInstallment]);

  useEffect(() => {
    if (!isInstallment) {
      onChangeInstallmentMonths(0);
      onChangeInterestRate(0);
    }
  }, [isInstallment, onChangeInstallmentMonths, onChangeInterestRate]);

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2.5}>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="labelLarge">ช่องทางชำระเงิน*</Typography>
        <PaymentMethodRadioGroup name="paymentMethod" control={control} />
      </Stack>
      {isCreditCard && (
        <Box display="grid" gridColumn="1/span 1" gap={2}>
          <FormBankAutocomplete
            id="bankId"
            name="bank"
            control={control}
            required
          />
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant="labelLarge">รูปแบบชำระ*</Typography>
            <FormRadioGroup row name="isInstallment" control={control}>
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="ชำระเต็มจำนวน"
              />
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="ผ่อนชำระ"
              />
            </FormRadioGroup>
          </Stack>
          <Stack direction="row" justifyContent="space-between" gap={2}>
            <FormNumericTextField
              fullWidth
              label="จำนวนผ่อนชำระ (เดือน)"
              name="installmentMonths"
              control={control}
              disabled={!isInstallment}
              required
            />
            <FormNumericTextField
              fullWidth
              label="ดอกเบี้ย (%)"
              name="interestRate"
              control={control}
              disabled={!isInstallment}
              allowZero
              required
            />
          </Stack>
        </Box>
      )}
    </Box>
  );
}

const schema = yup.object({
  paymentMethod: yup
    .string()
    .oneOf(Object.values(PaymentMethodCode))
    .required(),
  bank: BankAutocomplete.schema.when("paymentMethod", {
    is: PaymentMethodCode.CreditCard,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema,
  }),
  isInstallment: yup.string(),
  installmentMonths: yup
    .number()
    .when("isInstallment", {
      is: "true",
      then: (schema) => schema.integer().min(1).max(36).required(),
      otherwise: (schema) => schema,
    })
    .label("จำนวนผ่อนชำระ (เดือน)"),
  interestRate: yup
    .number()
    .when("isInstallment", {
      is: "true",
      then: (schema) =>
        schema.min(0).max(100).concat(yupDigitValidation(2)).required(),
      otherwise: (schema) => schema,
    })
    .label("ดอกเบี้ย (%)"),
});

const resolver = yupResolver(schema);

const defaultValues = {
  paymentMethod: PaymentMethodCode.Cash,
  isInstallment: "false",
} as PaymentEditorState;

export function usePaymentEditorForm() {
  return useForm({ resolver, defaultValues, mode: "onChange" });
}

PaymentEditor.schema = schema;
