import { Card, CardContent, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";

import {
  formatAbbreviateNumber,
  formatSaleByCategory,
  formatSaleByCategoryColor,
} from "@/formatter";
import { useSalesChartByTopStaff } from "@/hooks/useDashBoard";
import { ProductCategory } from "@/models";
import { configs } from "@/configs";

import type { DateTime } from "luxon";
import type {
  AxisConfig,
  AxisScaleConfig,
  ChartsXAxisProps,
  MakeOptional,
} from "@mui/x-charts/internals";

interface SalesByStaffProps {
  startDate: DateTime;
  endDate: DateTime;
}

export function SalesByStaff({ startDate, endDate }: SalesByStaffProps) {
  const { data, isFetching } = useSalesChartByTopStaff(startDate, endDate);

  const items =
    data?.map((item) => ({
      name: item.name,
      membership: item.membership,
      personalTraining: item.personalTraining,
      others: item.others,
    })) ?? [];

  const tickLabelStyle =
    items.length >= configs.adjustXAxisLabelBarChart
      ? {
          angle: 17,
          textAnchor: "start" as const,
          fontSize: 12,
        }
      : {};

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">
          ยอดขายแบ่งตามรายบุคคล สูงสุด 12 รายการ
        </Typography>

        <BarChart
          loading={isFetching}
          dataset={items}
          margin={{ bottom: 64 }}
          yAxis={[
            {
              label: "ยอดขาย (บาท)",
              valueFormatter: (value: number) => formatAbbreviateNumber(value),
            },
          ]}
          xAxis={[
            {
              scaleType: "band",
              dataKey: "name",
              categoryGapRatio: 0.6,
              tickLabelStyle,
            } as unknown as MakeOptional<
              AxisConfig<keyof AxisScaleConfig, any, ChartsXAxisProps>,
              "id"
            >, // https://github.com/mui/mui-x/issues/10334,
          ]}
          series={[
            ProductCategory.Membership,
            ProductCategory.PersonalTraining,
            ProductCategory.Others,
          ].map((category) => ({
            dataKey: category,
            label: formatSaleByCategory(category),
            stack: "total",
            color: formatSaleByCategoryColor(category),
          }))}
          height={400}
        />
      </CardContent>
    </Card>
  );
}
