import { useSnackbar } from "notistack";
import * as yup from "yup";
import { Divider, MenuItem, Stack } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";

import { configs } from "@/configs";
import { FormSelect } from "@/components/Select";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { Section } from "@/components/Section";
import { purchaseMembership, purchasePT } from "@/services/member";
import { PurchaseType } from "@/models";

import {
  PurchaseMembershipEditor,
  usePurchaseMembershipEditorForm,
} from "../../components/PurchaseMembershipEditor";
import {
  PurchaseProductPTEditor,
  usePurchaseProductPTEditorForm,
} from "../../components/PurchaseProductPTEditor";
import { FormSummaryPriceDetail } from "../../components/FormSummaryPriceDetail";
import {
  SaleEditor,
  useSaleForm,
} from "../../components/SaleAutocompleteEditor";
import {
  PaymentEditor,
  usePaymentEditorForm,
} from "../../../../components/Payment/PaymentEditor";

import type { PurchaseMembershipEditorState } from "../../components/PurchaseMembershipEditor";
import type { PurchaseProductPTEditorState } from "../../components/PurchaseProductPTEditor";
import type { PaymentEditorState } from "../../../../components/Payment/PaymentEditor";
import type { SelectChangeEvent } from "@mui/material";
import type { PurchaseMembershipInput, PurchasePTInput } from "@/models";

type PurchaseSingleProductDialogProps = {
  open: boolean;
  onClose: () => void;
  id: string;
};

export function PurchaseSingleProductDialog({
  open: isOpen,
  onClose,
  id,
}: PurchaseSingleProductDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, reset, getValues, trigger } = useForm<{
    selected?: PurchaseType;
  }>({
    resolver: yupResolver(
      yup.object({
        selected: yup
          .string()
          .oneOf(Object.values(PurchaseType))
          .label("รายการสินค้า")
          .required(),
      })
    ),
  });

  const { mutate: addPurchaseMembership, isLoading: loadingMembership } =
    useMutation(purchaseMembership, {
      onSuccess: async () => await onSuccess(),
      onError: (error) => onError(error),
    });
  const { mutate: addPurchasePT, isLoading: loadingPT } = useMutation(
    purchasePT,
    {
      onSuccess: async () => await onSuccess(),
      onError: (error) => onError(error),
    }
  );
  const queryClient = useQueryClient();

  async function onSuccess() {
    enqueueSnackbar("บันทึกข้อมูล สำเร็จ", { variant: "success" });
    await queryClient.refetchQueries("member");
    close();
  }

  function onError(error: unknown) {
    console.error(error);
    enqueueSnackbar(configs.unknownErrorMessage, { variant: "error" });
    close();
  }

  function onSubmitMembership(
    form: PurchaseMembershipEditorState,
    formPayment: PaymentEditorState
  ) {
    const data: PurchaseMembershipInput = {
      staffsId: getSaleValues().sale.id,
      productMembershipId: +form.membership.id,
      name: form.membership.name,
      startedAt: form.dateRange.start.toJSDate(),
      endedAt: form.dateRange.end.toJSDate(),
      remark: form.note ?? "",
      discountPercentage: form.discountPercentage ?? 0,
      discountBaht: form.discountBaht ?? 0,
      price: form.membership.price,
      paymentMethodCode: formPayment.paymentMethod,
      bankId: formPayment.bank?.id,
      isInstallment: formPayment.isInstallment === "true",
      installmentMonths: formPayment.installmentMonths || undefined,
      interestRate: formPayment.interestRate || undefined,
    };

    addPurchaseMembership({
      id: +id,
      data,
    });
  }

  function onSubmitProductPT(
    form: PurchaseProductPTEditorState,
    formPayment: PaymentEditorState
  ) {
    const data: PurchasePTInput = {
      staffsId: getSaleValues().sale.id,
      productPTId: form.packagePT?.id ? +form.packagePT?.id : 0,
      name: form.packagePT?.name ?? "",
      startedAt: form.dateRange.start.toJSDate(),
      endedAt: form.dateRange.end.toJSDate(),
      remark: form.note ?? "",
      discountPercentage: form.discountPercentage ?? 0,
      discountBaht: form.discountBaht ?? 0,
      price: form.packagePT?.price ?? 0,
      paymentMethodCode: formPayment.paymentMethod,
      bankId: formPayment.bank?.id,
      isInstallment: formPayment.isInstallment === "true",
      installmentMonths: formPayment.installmentMonths || undefined,
      interestRate: formPayment.interestRate || undefined,
    };

    addPurchasePT({
      id: +id,
      data,
    });
  }

  const title = "ซื้อสินค้า";

  const {
    control: saleControl,
    trigger: saleTrigger,
    reset: saleReset,
    getValues: getSaleValues,
  } = useSaleForm();
  const {
    control: membershipControl,
    trigger: membershipTrigger,
    reset: membershipReset,
    getValues: getMembershipValues,
  } = usePurchaseMembershipEditorForm();
  const {
    control: packagePTControl,
    trigger: packagePTTrigger,
    reset: packagePTReset,
    getValues: getProductPTValues,
  } = usePurchaseProductPTEditorForm();

  const {
    control: paymentControl,
    trigger: paymentTrigger,
    reset: paymentReset,
    getValues: getPaymentValues,
  } = usePaymentEditorForm();

  const purchaseConfigs = {
    [PurchaseType.Membership]: {
      isValid: membershipTrigger,
      submit: () =>
        onSubmitMembership(getMembershipValues(), getPaymentValues()),
      form: <PurchaseMembershipEditor control={membershipControl} />,
    },
    [PurchaseType.ProductPT]: {
      isValid: packagePTTrigger,
      submit: () => onSubmitProductPT(getProductPTValues(), getPaymentValues()),
      form: <PurchaseProductPTEditor control={packagePTControl} />,
    },
  };

  async function onSubmit() {
    const selected = getValues().selected;

    const isSaleValid = await saleTrigger();
    const isPaymentValid = await paymentTrigger();
    if (!(await trigger()) || !selected) return;

    const { isValid, submit } = purchaseConfigs[selected];

    if (!(await isValid()) || !isSaleValid || !isPaymentValid) return;

    submit();
  }

  function onChangeType(event: SelectChangeEvent<PurchaseType | undefined>) {
    event.target.value === PurchaseType.Membership
      ? packagePTReset()
      : membershipReset();
  }

  function close() {
    onClose();
    reset();
    packagePTReset();
    membershipReset();
    paymentReset();
    saleReset();
  }

  return (
    <ConfirmDialog
      title={title}
      loading={loadingMembership || loadingPT}
      open={isOpen}
      onConfirm={onSubmit}
      onClose={close}
    >
      <Stack gap={2.5}>
        <Section columns={1} label={"พนักงานขาย"}>
          <SaleEditor control={saleControl} />
        </Section>
        <Divider />
        <FormSelect
          label="รายการสินค้า"
          name="selected"
          control={control}
          onChange={onChangeType}
          required
        >
          {[PurchaseType.Membership, PurchaseType.ProductPT].map(
            (purchaseType) => (
              <MenuItem key={purchaseType} value={purchaseType}>
                {purchaseType === PurchaseType.Membership
                  ? "แพ็กเกจสมาชิก"
                  : "แพ็กเกจเทรนเนอร์"}
              </MenuItem>
            )
          )}
        </FormSelect>
        <Controller
          name="selected"
          control={control}
          render={({ field }) => {
            if (!field.value) return <div />;
            return purchaseConfigs[field.value].form;
          }}
        />
        <Divider />
        <PaymentEditor control={paymentControl} />
        <FormSummaryPriceDetail
          membershipControl={membershipControl}
          packagePTControl={packagePTControl}
        />
      </Stack>
    </ConfirmDialog>
  );
}
