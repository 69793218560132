import { Box } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import {
  BankAutocomplete,
  FormBankAutocomplete,
} from "@/features/member/pages/BankAutocomplete";
import { FormNumericTextField } from "@/components/NumericTextField";
import { yupDigitValidation } from "@/lib/yup/validation";

import type { ControllerProps } from "react-hook-form";
import type { InferType } from "yup";

export type BankFeeEditorState = InferType<typeof schema>;

type BankFeeEditorProps = Pick<
  ControllerProps<BankFeeEditorState>,
  "control"
> & { isEdit?: boolean };

export function BankFeeEditor({ control, isEdit = false }: BankFeeEditorProps) {
  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr 2fr" gap={2.5}>
      <FormBankAutocomplete
        control={control}
        name="bank"
        label="ชื่อธนาคาร/สถาบันการเงิน"
        required
        sx={{ gridColumn: "1/span 2" }}
        disabled={isEdit}
      />
      <FormNumericTextField
        id="packageNameInput"
        control={control}
        name="feeAmount"
        label="ค่าธรรมเนียม (%)"
        required
        sx={{ gridColumn: "1/span 1" }}
        allowZero
      />
    </Box>
  );
}

export function useBankFeeEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  bank: BankAutocomplete.schema.required(),
  feeAmount: yup
    .number()
    .label("ค่าธรรมเนียม (%)")
    .min(0)
    .max(100)
    .concat(yupDigitValidation(4))
    .required(),
});

const resolver = yupResolver(schema);

const defaultValues = {
  feeAmount: 0,
} as BankFeeEditorState;
