import {
  getDashboardForSalesAllSummary,
  getDashboardForSalesAllSummaryInput,
  getSalesChartByCategory,
  getSalesChartByInstallment,
  getSalesChartByTopMembership,
  getSalesChartByPaymentType,
  getSalesChartByTopProduct,
  getSalesChartByTopStaff,
  getSalesChartByTopPersonalTraining,
} from "@/services/dashboard";
import { DateTime } from "luxon";
import { useQuery } from "react-query";

const useGetSalesReport = (param: getDashboardForSalesAllSummaryInput) => {
  return useQuery({
    queryKey: ["sales-report-csv", param],
    queryFn: () => getDashboardForSalesAllSummary(param),
  });
};

const useSalesChartByPaymentType = (startedAt: DateTime, endedAt: DateTime) => {
  return useQuery({
    queryKey: ["sales-chart-by-payment-type", startedAt, endedAt],
    queryFn: () => getSalesChartByPaymentType(startedAt, endedAt),
    enabled: !!startedAt && !!endedAt,
  });
};

const useSalesChartByInstallment = (startedAt: DateTime, endedAt: DateTime) => {
  return useQuery({
    queryKey: ["sales-chart-by-installment", startedAt, endedAt],
    queryFn: () => getSalesChartByInstallment(startedAt, endedAt),
    enabled: !!startedAt && !!endedAt,
  });
};

const useSalesChartByTopStaff = (startedAt: DateTime, endedAt: DateTime) => {
  return useQuery({
    queryKey: ["sales-chart-by-top-staff", startedAt, endedAt],
    queryFn: () => getSalesChartByTopStaff(startedAt, endedAt),
    enabled: !!startedAt && !!endedAt,
  });
};

const useSalesChartByCategory = (startedAt: DateTime, endedAt: DateTime) => {
  return useQuery({
    queryKey: ["sales-chart-by-category", startedAt, endedAt],
    queryFn: () => getSalesChartByCategory(startedAt, endedAt),
    enabled: !!startedAt && !!endedAt,
  });
};

const useSalesChartByPersonalTraining = (
  startedAt: DateTime,
  endedAt: DateTime
) => {
  return useQuery({
    queryKey: ["sales-chart-by-personal-training", startedAt, endedAt],
    queryFn: () => getSalesChartByTopPersonalTraining(startedAt, endedAt),
    enabled: !!startedAt && !!endedAt,
  });
};

const useSalesChartByMembership = (startedAt: DateTime, endedAt: DateTime) => {
  return useQuery({
    queryKey: ["sales-chart-by-membership", startedAt, endedAt],
    queryFn: () => getSalesChartByTopMembership(startedAt, endedAt),
    enabled: !!startedAt && !!endedAt,
  });
};

const useSalesChartByProduct = (startedAt: DateTime, endedAt: DateTime) => {
  return useQuery({
    queryKey: ["sales-chart-by-product", startedAt, endedAt],
    queryFn: () => getSalesChartByTopProduct(startedAt, endedAt),
    enabled: !!startedAt && !!endedAt,
  });
};

export {
  useGetSalesReport,
  useSalesChartByPaymentType,
  useSalesChartByInstallment,
  useSalesChartByTopStaff,
  useSalesChartByCategory,
  useSalesChartByPersonalTraining,
  useSalesChartByMembership,
  useSalesChartByProduct,
};
