import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { DateTime } from "luxon";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useRef, useState } from "react";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { fromJSDate } from "@/lib/dateTime";
import { mapOptional, useRequireParams } from "@/utils";
import { getTrainerSchedules } from "@/services/trainer";
import { formatProfiles } from "@/formatter";
import { configs } from "@/configs";

import { useTrainerProfilePage } from "./TrainerProfilePage";
import { ScheduleLabel } from "./ScheduleLabel";
import { ClassScheduleDialog } from "../../../../components/ClassScheduleDialog";
import { PersonalTrainingScheduleDialog } from "./PersonalTrainingScheduleDialog";
import { AddTrainerScheduleDialog } from "./AddTrainerScheduleDialog";

import type { ComponentProps } from "react";

type FullCalendarProps = ComponentProps<typeof FullCalendar>;

const thLocale = {
  code: "th",
  week: {
    dow: 0,
    doy: 4,
  },
  buttonText: {
    prev: "ก่อนหน้า",
    next: "ถัดไป",
    prevYear: "ปีก่อนหน้า",
    nextYear: "ปีถัดไป",
    year: "ปี",
    today: "วันนี้",
    month: "เดือน",
    week: "สัปดาห์",
    day: "วัน",
    list: "รายการ",
  },
  weekText: "สัปดาห์",
  allDayText: "เวลา",
  moreLinkText: "เพิ่มเติม",
  noEventsText: "ไม่มีกิจกรรมที่จะแสดง",
};
const headerToolbar = { left: "", center: "", right: "" };
const views: FullCalendarProps["views"] = {
  dayGridMonth: {
    titleFormat: { year: "numeric", month: "long" },
    displayEventTime: false,
  },
  timeGridWeek: {
    titleFormat: {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
    dayHeaderFormat(format) {
      return fromJSDate(format.date.marker).setLocale("th").toFormat("EEE M/d");
    },
  },
  timeGridDay: {
    titleFormat: {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
  },
  listMonth: {
    titleFormat: {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
  },
};

const plugins = [dayGridPlugin, timeGridPlugin, listPlugin];
const viewModes = {
  listMonth: "รายการ",
  dayGridMonth: "เดือน",
  timeGridWeek: "สัปดาห์",
  timeGridDay: "วัน",
} as const;

type DialogType = "class-schedule" | "pt-schedule";

const QUERY_KEY = "trainerCalendar";

export function TrainerCalendar() {
  const { id: trainerId } = useRequireParams(["id"]);
  const { isPersonalTraining } = useTrainerProfilePage();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const [dateRange, setDateRange] = useState<{
    start: DateTime;
    end: DateTime;
  }>();

  const dateRangeISO = mapOptional(dateRange, ({ start, end }) => ({
    start: start.toISO(),
    end: end.toISO(),
  })) ?? { start: "", end: "" };

  const { start, end } = dateRangeISO;

  const { data } = useQuery(
    [QUERY_KEY, start, end],
    () => getTrainerSchedules({ id: trainerId, start, end }),
    { enabled: !!dateRange, keepPreviousData: true }
  );

  const calendarRef = useRef<FullCalendar>(null);

  const onDatesSet: FullCalendarProps["datesSet"] = ({ start, end }) => {
    setDateRange({ start: fromJSDate(start), end: fromJSDate(end) });
  };

  const onEventClick: FullCalendarProps["eventClick"] = ({ event }) => {
    searchParams.set(
      "dialog",
      (event.extendedProps as { dialog: DialogType }).dialog
    );
    searchParams.set("id", event.id);

    setSearchParams(searchParams, { replace: true });
  };

  const classSchedules = data?.filter((schedule) => !!schedule.class) ?? [];
  const personalTrainingSchedules =
    data?.filter((schedule) => !schedule.class) ?? [];

  const events = classSchedules
    .map((schedule) => ({
      id: schedule.id.toString(),
      color: theme.palette.info.main,
      title: schedule?.class?.name ?? "",
      start: schedule.startedAt.toISO(),
      end: schedule.endedAt.toISO(),
      dialog: "class-schedule",
    }))
    .concat(
      personalTrainingSchedules.map(({ id, bookings, startedAt, endedAt }) => ({
        id: id.toString(),
        color: theme.palette.warning.main,
        title: formatProfiles(bookings.map(({ member }) => member)),
        start: startedAt.toISO(),
        end: endedAt.toISO(),
        dialog: "pt-schedule",
      }))
    );

  const vm = mapOptional(calendarRef.current?.getApi(), (api) => {
    const { type: currentView, title, currentStart, currentEnd } = api.view;

    return {
      title,
      currentView,
      disabledToday:
        currentStart.getTime() <= Date.now() &&
        Date.now() <= currentEnd.getTime(),
      today: () => api.today(),
      next: () => api.next(),
      previous: () => api.prev(),
    };
  });

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("id");
    setSearchParams(searchParams);
  }

  const addScheduleDialog = {
    open: searchParams.get("dialog") === "schedule-add",
    onClose: onCloseDialog,
    trainerId,
    queryKey: [QUERY_KEY, start, end],
  };

  function onClickAddSchedule() {
    searchParams.set("dialog", "schedule-add");
    setSearchParams(searchParams);
  }

  const classScheduleDialog = {
    open: Boolean(
      searchParams.get("dialog") === "class-schedule" && searchParams.get("id")
    ),
    scheduleId: searchParams.get("id"),
    onClose: onCloseDialog,
  };

  const personalTrainingScheduleDialog = {
    open: Boolean(
      searchParams.get("dialog") === "pt-schedule" && searchParams.get("id")
    ),
    scheduleId: searchParams.get("id"),
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  return (
    <Card>
      <Stack>
        <CardHeader
          title={
            <Stack direction="row" gap={2.5} alignItems="baseline">
              <ScheduleLabel
                variant="caption"
                color={theme.palette.info.main}
                gap={0}
              >
                สอนคลาส
              </ScheduleLabel>
              <ScheduleLabel
                variant="caption"
                color={theme.palette.warning.main}
                gap={0}
              >
                เทรนนิ่งส่วนตัว
              </ScheduleLabel>
            </Stack>
          }
          action={
            isPersonalTraining ? (
              <Button
                variant="contained"
                onClick={onClickAddSchedule}
                disabled={!isPersonalTraining}
              >
                เพิ่มการนัดหมาย
              </Button>
            ) : (
              <></>
            )
          }
        />
        <CardContent>
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Button
                variant="contained"
                disabled={vm?.disabledToday ?? true}
                onClick={vm?.today}
              >
                วันนี้
              </Button>
              <Tooltip title="ก่อนหน้า">
                <IconButton sx={{ ml: 4 }} onClick={vm?.previous}>
                  <ChevronLeft />
                </IconButton>
              </Tooltip>
              <Tooltip title="ถัดไป">
                <IconButton onClick={vm?.next}>
                  <ChevronRight />
                </IconButton>
              </Tooltip>
            </Box>
            <Typography variant="h5">{vm?.title}</Typography>
            <ButtonGroup variant="contained" size="large" color="inherit">
              {Object.entries(viewModes).map(([key, title]) => (
                <Button
                  key={key}
                  sx={{
                    bgcolor: key === vm?.currentView ? "grey.100" : "grey.300",
                  }}
                  onClick={() => calendarRef.current?.getApi().changeView(key)}
                >
                  {title}
                </Button>
              ))}
            </ButtonGroup>
          </Stack>
          <FullCalendar
            ref={calendarRef}
            plugins={plugins}
            initialView="dayGridMonth"
            locale={thLocale}
            headerToolbar={headerToolbar}
            events={events}
            themeSystem="mui"
            datesSet={onDatesSet}
            eventClick={onEventClick}
            views={views}
            timeZone={configs.timezone}
            dayMaxEvents={3}
          />
        </CardContent>
      </Stack>
      <ClassScheduleDialog {...classScheduleDialog} />
      <PersonalTrainingScheduleDialog {...personalTrainingScheduleDialog} />
      <AddTrainerScheduleDialog {...addScheduleDialog} />
    </Card>
  );
}
