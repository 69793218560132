import { api } from "@/client/api";
import { configs } from "@/configs";

export type BankFee = {
  id: number;
  bankId: number;
  bankName: string;
  paymentMethodId: number;
  paymentMethodName: string;
  feeAmount: number;
};

export type CreateBankFeeRequest = {
  bankId: number;
  feeAmount: number;
};

export type UpdateBankFeeRequest = {
  id: number;
  feeAmount: number;
};

export async function getBankFees() {
  const response = await api.get<BankFee[]>(`${configs.apiUrl}/bank-fees`);
  return response.data;
}

export const createBankFee = async (data: CreateBankFeeRequest) => {
  const response = await api.post<string>(`${configs.apiUrl}/bank-fees`, data);
  return response.data;
};

export const updateBankFee = async (input: UpdateBankFeeRequest) => {
  const { id, ...data } = input;

  const response = await api.put<string>(
    `${configs.apiUrl}/bank-fees/${id}`,
    data
  );
  return response.data;
};

export const deleteBankFee = async (id: number) => {
  const response = await api.delete<string>(
    `${configs.apiUrl}/bank-fees/${id}`
  );
  return response.data;
};
