import { TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";

import { useController } from "react-hook-form";

import type { TextFieldProps } from "@mui/material";
import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";

export type NumericTextFieldProps =
  | Omit<TextFieldProps, "value" | "onChange"> & {
      value?: number;
      onChange?: (
        event: ChangeEvent<HTMLInputElement>,
        value: number | null
      ) => void;
      allowZero?: boolean;
    };

export function NumericTextField({
  value,
  onBlur,
  onFocus,
  onChange,
  allowZero,
  ...props
}: NumericTextFieldProps) {
  const [isFocus, setIsFocus] = useState(false);
  const proxy =
    !value && !allowZero
      ? ""
      : isFocus
      ? value?.toString()
      : value?.toLocaleString(undefined, { maximumFractionDigits: 100 });
  const type = isFocus ? "number" : "text";

  return (
    <TextField
      value={proxy}
      onFocus={(event) => {
        setIsFocus(true);
        onFocus?.(event);
      }}
      onBlur={(event) => {
        setIsFocus(false);
        onBlur?.(event);
      }}
      onChange={(event) => {
        const value = (event.target as HTMLInputElement).valueAsNumber;
        onChange?.(
          event as ChangeEvent<HTMLInputElement>,
          Number.isNaN(value) ? null : value
        );
      }}
      type={type}
      inputMode="decimal"
      {...props}
    />
  );
}

export type FormNumericTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Pick<ControllerProps<TFieldValues, TName>, "name" | "control"> &
  Omit<TextFieldProps, "name" | "value" | "onChange">;
export function FormNumericTextField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  onBlur,
  allowZero = false,
  ...props
}: FormNumericTextFieldProps<TFieldValues, TName> & { allowZero?: boolean }) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  const value: number | undefined = field.value as unknown as number;

  return (
    <NumericTextField
      inputRef={field.ref}
      value={value || 0}
      onChange={(_, value) => field.onChange(value || 0)}
      onBlur={(event) => {
        onBlur?.(event);
        field.onBlur();
      }}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      allowZero={allowZero}
      {...props}
    />
  );
}
