import * as yup from "yup";
import { isTimeAfter } from "@/utils";

export function isYoutubeVideoLink(link: string): boolean {
  try {
    const url = new URL(link);
    const searchParams = new URLSearchParams(url.search);

    url.search = searchParams.toString();

    return (
      /https:\/\/www\.youtube\.com\/watch\?v=\w{11}/.test(url.toString()) ||
      /https:\/\/youtu\.be\/\w{11}/.test(url.toString())
    );
  } catch (error) {
    return false;
  }
}

export const youtubeLink = yup
  .string()
  .test(
    "is-youtube-link",
    "Link ต้องเป็น url จาก Youtube เท่านั้น",
    (value) =>
      typeof value === "undefined" || value === "" || isYoutubeVideoLink(value)
  );

export function isGoogleMapLink(link: string): boolean {
  try {
    return (
      /https:\/\/www\.google\.co\.th\/maps\/place\/./.test(link) ||
      /https:\/\/www\.google\.com\/maps\/./.test(link) ||
      /https:\/\/goo\.gl\/maps\/./.test(link)
    );
  } catch (error) {
    return false;
  }
}

export const googleMapLink = yup
  .string()
  .test(
    "is-google-map-link",
    "Link ต้องเป็น url จาก Google Map เท่านั้น",
    (value) =>
      typeof value === "undefined" || value === "" || isGoogleMapLink(value)
  );

export const time = yup.string().test({
  name: "time",
  message: "text must be in format 'HH:mm'",
  test: (value = "") => /^(0\d|1\d|2[0-3]):[0-5]\d$/.test(value),
});

export function timeAfter<T extends yup.StringSchema>(
  key: string,
  message: string,
  schema: T
) {
  return schema.test({
    name: "time-after",
    message,
    params: { [key]: yup.ref(key) },
    test: (value = "", context) =>
      isTimeAfter(value, (context.parent as Record<string, string>)[key]),
  });
}

export const price = yup.number().min(0, "ระบุตัวเลขไม่น้อยกว่า ${min}");

export const decimal = yup
  .number()
  .test(
    "maxDigitsAfterDecimal",
    "number field must have 10 digits after decimal or less",
    (number) => /^\d+(\.\d{0,10})?$/.test(number?.toString() ?? "0")
  );

export const decimalValidation = (number?: number, digit = 10) => {
  const regex = new RegExp(`^\\d+(\\.\\d{0,${digit}})?$`);
  return regex.test(number?.toString() ?? "0");
};

export function yupDigitValidation(digit = 10) {
  return yup
    .number()
    .test(
      "max-digits-after-decimal",
      "${path} ต้องมีทศนิยมน้อยกว่าหรือเท่ากับ " +
        digit.toString() +
        " ตำแหน่ง",
      (number) => decimalValidation(number, digit)
    );
}
