import { api } from "@/client/api";
import { configs } from "@/configs";

export type PaymentMethod = {
  id: number;
  name: string;
  code: string;
};

export async function getPaymentMethods() {
  const response = await api.get<PaymentMethod[]>(
    `${configs.apiUrl}/payment-methods`
  );
  return response.data;
}


