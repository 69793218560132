import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { createBankFee } from "@/services/bankFeeService";
import { getApiErrorMessage, refetchQueries } from "@/utils";

import { useBankFeeEditorForm } from "./BankFeeEditor";
import { BankFeeEditorDialog } from "./BankFeeEditorDialog";

import type { AxiosErrorWithData } from "@/client/api";

export function AddBankFeeDialog({
  open: isOpen,
  onClose,
  fetchKeys = [],
}: {
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useBankFeeEditorForm();
  const queryClient = useQueryClient();

  const { mutate: add, isLoading } = useMutation(createBankFee, {
    onSuccess: async () => {
      enqueueSnackbar(`เพิ่มค่าธรรมเนียมสำเร็จ`, { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
    },
  });

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    const data = {
      bankId: form.bank.id,
      feeAmount: form.feeAmount ?? 0,
    };
    add(data);
  });
  const title = "เพิ่มค่าธรรมเนียม";

  return (
    <BankFeeEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
