import { styled } from "@mui/material/styles";
import { useDrawingArea } from "@mui/x-charts/hooks";

const StyledText = styled("text")(() => ({
  textAnchor: "middle",
  dominantBaseline: "central",
}));

export function PieCenterLabel({
  children,
  heightOffset = 0,
  fontSize = 24,
}: {
  children: React.ReactNode;
  heightOffset?: number;
  fontSize?: number;
}) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText
      x={left + width / 2}
      y={top + height / 2 + heightOffset}
      sx={{ fontSize, fontWeight: 400 }}
    >
      {children}
    </StyledText>
  );
}
