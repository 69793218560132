import { Card, CardContent, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { DateTime } from "luxon";

import { useSalesChartByCategory } from "@/hooks/useDashBoard";
import {
  formatAbbreviateNumber,
  formatSaleByCategory,
  formatSaleByCategoryColor,
} from "@/formatter";
import { ProductCategory } from "@/models";

import type {
  AxisConfig,
  AxisScaleConfig,
  ChartsXAxisProps,
  MakeOptional,
} from "@mui/x-charts/internals";

interface SalesByCategoryProps {
  startDate: DateTime;
  endDate: DateTime;
}

export function SalesByCategory({ startDate, endDate }: SalesByCategoryProps) {
  const { data: raw, isFetching } = useSalesChartByCategory(startDate, endDate);

  const chartDate =
    raw?.map((item) => ({
      name: formatSaleByCategory(item.name as unknown as ProductCategory),
      value: item.value,
      color: formatSaleByCategoryColor(item.name as unknown as ProductCategory),
    })) ?? [];

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">
          ยอดขายแบ่งตามประเภทสินค้าหรือบริการ
        </Typography>
        <BarChart
          loading={isFetching}
          yAxis={[
            {
              label: "ยอดขาย (บาท)",
              valueFormatter: (value: number) => formatAbbreviateNumber(value),
            },
          ]}
          xAxis={[
            {
              data: chartDate.map((d) => d.name),
              scaleType: "band",
              colorMap: {
                type: "ordinal",
                colors: chartDate.map(({ color }) => color),
              },
              categoryGapRatio: 0.6,
            } as unknown as MakeOptional<
              AxisConfig<keyof AxisScaleConfig, any, ChartsXAxisProps>,
              "id"
            >, // https://github.com/mui/mui-x/issues/10334,
          ]}
          series={[{ data: chartDate.map((d) => d.value) }]}
          height={350}
        />
      </CardContent>
    </Card>
  );
}
