import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useMutation } from "react-query";
import { LoadingButton } from "@mui/lab";

import { getDocumentTitle, mapOptional } from "@/utils";
import { fromISO, now } from "@/lib/dateTime";
import { DatePicker } from "@/components/DatePicker";
import { getDashboardForSalesAllSummaryCsv } from "@/services/dashboard";
import { configs } from "@/configs";

import type { TabsProps } from "@mui/material";
import type { DateTime } from "luxon";

export function SalesAllSummaryPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const path = location?.pathname ?? "";

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(`${value}?${searchParams.toString()}`, { replace: true });
  };

  const startedAtISO = searchParams.get("startDate");
  const endedAtISO = searchParams.get("endDate");

  const startDate = mapOptional(startedAtISO, fromISO) ?? now().startOf("day");
  const endDate = mapOptional(endedAtISO, fromISO) ?? now().endOf("day");

  const { mutate: downloadCsv, isLoading } = useMutation(
    getDashboardForSalesAllSummaryCsv,
    {
      onSuccess: (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        const fileName = `Report_Sale_Summary_${startDate.toFormat(
          "yyyyMMdd"
        )}_${endDate.toFormat("yyyyMMdd")}.csv`;

        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
    }
  );

  const isTableTab = path.includes("table");

  const onChangeStart = (value: DateTime | null) => {
    const today = now().startOf("day");
    const start = value ?? today;
    const diff = endDate.diff(start, ["days", "hours"]);

    const maxEndDate = start.plus({
      days: configs.saleDashboardMaxQueryDays - 1,
    });

    if (diff.days + 1 > configs.saleDashboardMaxQueryDays) {
      searchParams.set("endDate", maxEndDate.endOf("day").toISO());
    }

    if (diff.days < 0) {
      const newEndDate = maxEndDate > today ? today : maxEndDate;
      searchParams.set("endDate", newEndDate.endOf("day").toISO());
    }

    searchParams.set("page", "1");
    searchParams.set("startDate", value?.toISO() ?? "");
    setSearchParams(searchParams);
  };

  const onChangeEnd = (value: DateTime | null) => {
    searchParams.set("page", "1");
    searchParams.set("endDate", value?.endOf("day").toISO() ?? "");
    setSearchParams(searchParams);
  };

  const handleDownloadReport = () => {
    try {
      downloadCsv({
        startedAt: startDate,
        endedAt: endDate,
      });
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const maxEndDate = startDate.plus({
    days: configs.saleDashboardMaxQueryDays - 1,
  });

  return (
    <Stack gap={4}>
      <Helmet>
        <title>{getDocumentTitle("วิเคราะห์การขาย")}</title>
      </Helmet>
      <Tabs value={path} onChange={onChangeTab}>
        <Tab
          label="สรุปยอดขาย"
          value={"/dashboard/sales-all-summary"}
          sx={{ width: 180 }}
        />
        <Tab
          label="รายงานยอดขาย"
          value={"/dashboard/sales-all-summary/table"}
          sx={{ width: 180 }}
        />
      </Tabs>
      <Stack gap={3}>
        <Stack direction="row" spacing={2} alignItems="center">
          <DatePicker
            disableFuture
            label="เริ่มวันที่"
            inputFormat="dd MMMM yyyy"
            value={startDate}
            onChange={onChangeStart}
          />
          <Typography>-</Typography>
          <DatePicker
            disableFuture
            label="สิ้นสุดวันที่"
            inputFormat="dd MMMM yyyy"
            value={endDate}
            minDate={startDate}
            maxDate={maxEndDate}
            onChange={onChangeEnd}
          />
          <Box flexGrow={1} />
          <LoadingButton
            variant="contained"
            onClick={handleDownloadReport}
            sx={{ visibility: isTableTab ? "visible" : "hidden" }}
            loading={isLoading}
          >
            Download Report
          </LoadingButton>
        </Stack>
        <Outlet />
      </Stack>
    </Stack>
  );
}
