import { Box, Breadcrumbs, Tab, Tabs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import type { TabsProps } from "@mui/material";

export function ProductAndPackageListPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location?.pathname ?? "/products-and-packages";

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(value, { replace: true });
  };

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 5 }}
      >
        <Typography color="text.primary">แพ็กเกจและสินค้า</Typography>
      </Breadcrumbs>
      <Tabs value={path} sx={{ mb: 2 }} onChange={onChangeTab}>
        <Tab
          label="แพ็กเกจสมาชิก"
          sx={{ width: 174 }}
          value="/products-and-packages"
        ></Tab>
        <Tab
          label="แพ็กเกจเทรนเนอร์"
          sx={{ width: 174 }}
          value="/products-and-packages/pt"
        ></Tab>
        <Tab
          label="สินค้า"
          sx={{ width: 174 }}
          value="/products-and-packages/products"
        ></Tab>
        <Tab
          label="ค่าธรรมเนียม"
          sx={{ width: 174 }}
          value="/products-and-packages/bank-fees"
        ></Tab>
      </Tabs>
      <Outlet />
    </Box>
  );
}
