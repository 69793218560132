import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import { ProfileImageEditor } from "@/components/ProfileImageEditor";

import { StaffProfileDetail } from "../../components/StaffProfileDetail";
import {
  RemoveStaffDialog,
  RemoveStaffDialogProps,
} from "../../components/RemoveStaffDialog";
import { EditStaffDialog } from "../StaffListPage/EditStaffDialog";

import type { Staff } from "@/models";

export type StaffProfileProps = {
  data: Staff;
};

export function StaffProfile({ data }: StaffProfileProps) {
  const { id, code, profile, roles: staffRoles } = data;
  const QUERY_KEY = `staff_${id}`;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentUser = useCurrentUser();
  const dialog = searchParams.get("dialog");

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  function openEditDialog() {
    searchParams.set("dialog", "edit");
    setSearchParams(searchParams);
  }

  function openRemoveDialog() {
    searchParams.set("dialog", "remove");
    searchParams.set("id", id.toString());
    setSearchParams(searchParams);
  }

  const editDialog = {
    open: dialog === "edit",
    onClose: onCloseDialog,
    id: id.toString(),
    data,
    fetchKey: QUERY_KEY,
  };

  const removeDialog: RemoveStaffDialogProps = {
    open: dialog === "remove",
    onClose(reason) {
      onCloseDialog();
      if (reason === "success") {
        navigate("..");
      }
    },
    id: id.toString(),
  };

  const profileImageEditor = {
    profile,
    fetchKey: QUERY_KEY,
  };

  const isAdmin = staffRoles.find((role) => role.id === 1);
  const showActionButton = currentUser.isAdmin || !isAdmin;

  return (
    <Card>
      <Stack>
        <CardHeader
          title={
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="h6">{code}</Typography>
            </Stack>
          }
          action={
            <Stack direction="row">
              {showActionButton && (
                <>
                  <Tooltip title="แก้ไขสมาชิก">
                    <IconButton onClick={openEditDialog}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="ลบสมาชิก">
                    <IconButton onClick={openRemoveDialog}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Stack>
          }
        />
        <CardContent sx={{ py: 0 }}>
          <Stack gap={4}>
            <ProfileImageEditor {...profileImageEditor} />
            <StaffProfileDetail data={data} />
          </Stack>
          <EditStaffDialog {...editDialog} />
          <RemoveStaffDialog {...removeDialog} />
        </CardContent>
      </Stack>
    </Card>
  );
}
