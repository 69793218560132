import {
  BloodGroup,
  Day,
  DayString,
  Gender,
  MaritalStatus,
  PersonalTax,
  QuotaUnit,
  TrainerContract,
  ScheduleStatus,
  BookingStatus,
  Staff,
  Member,
  MemberStatus,
  PurchaseType,
  DurationUnit,
  FitnessType,
  ProductType,
  Channel,
  CustomerStatus,
  CustomerStatusStamp,
  OpportunityStatus,
  ReceiptStatus,
  DiscountType,
  FacilityType,
  FacilityBookingStatus,
  TrainingGoal,
  TrainingStatus,
  PermissionGroupType,
  ProductCategory,
} from "@/models";
import { fromDurationISO, fromDurationObject } from "@/lib/duration";

import type { Profile } from "@/models";
import type { DateTime, Duration, DurationObjectUnits } from "luxon";
import { colors } from "@mui/material";

export function formatFullDate(date: DateTime) {
  return date.setLocale("th-TH").toFormat("EEEEที่ d MMM yyyy");
}

export function formatDate(date: DateTime) {
  return date.toFormat("d/M/yyyy");
}

export function formatTime(date: DateTime) {
  return date.toFormat("H:mm");
}

export function formatDateTime(date: DateTime, separator = "") {
  return `${formatDate(date)} ${separator} ${formatTime(date)}`;
}

export function formatDateTimeRange(start: DateTime, end: DateTime) {
  const { days } = end.diff(start, ["days", "minutes"]);
  const isSameDay = days === 0;

  return isSameDay
    ? `${formatDateTime(start)} - ${formatTime(end)}`
    : `${formatDateTime(start)} - ${formatDateTime(end)}`;
}

export function formatDateRange(start: DateTime, end: DateTime) {
  return `${formatDate(start)} - ${formatDate(end)}`;
}

export function formatTimeRange(start: DateTime, end: DateTime) {
  return `${formatTime(start)} - ${formatTime(end)}`;
}

export function formatPrice(price: number, unit = "บาท") {
  return !isNaN(price)
    ? [formatNumber(price), unit].filter(Boolean).join(" ")
    : "-";
}

export function formatNumber(value: number): string {
  return value.toLocaleString();
}

export function formatAbbreviateNumber(value: number) {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 1,
    notation: "compact",
    compactDisplay: "short",
  }).format(value);
}

const scheduleStatusMapper = {
  [ScheduleStatus.Active]: "เปิดจองแล้ว",
  [ScheduleStatus.Upcoming]: "รอเปิดจอง",
  [ScheduleStatus.Completed]: "เสร็จสิ้น",
  [ScheduleStatus.Cancelled]: "ยกเลิก",
};
export function formatScheduleStatus(status: ScheduleStatus) {
  return scheduleStatusMapper[status];
}

const channelMapper = {
  [Channel.Facebook]: "Facebook",
  [Channel.Line]: "Line",
  [Channel.Google]: "Google",
  [Channel.Instagram]: "Instagram",
  [Channel.Friend]: "เพื่อนแนะนำ",
  [Channel.Event]: "งานอีเวนท์",
  [Channel.Brochure]: "โบวชัวร์",
  [Channel.Other]: "อื่นๆ",
};
export function formatChannel(channel: Channel) {
  return channelMapper[channel];
}

const bookingStatusMapper = {
  [BookingStatus.Booked]: "จองแล้ว",
  [BookingStatus.Waiting]: "รอคิว",
  [BookingStatus.Cancelled]: "ยกเลิก",
  [BookingStatus.Pending]: "รอการยืนยัน",
  [BookingStatus.Absent]: "ไม่เข้าร่วม",
  [BookingStatus.Attended]: "เข้าร่วม",
};
export function formatBookingStatus(status: BookingStatus) {
  return bookingStatusMapper[status];
}

const reservationStatusMapper = {
  [BookingStatus.Booked]: "รอการเข้าร่วม",
  [BookingStatus.Waiting]: "รอคิว",
  [BookingStatus.Cancelled]: "ยกเลิก",
  [BookingStatus.Pending]: "รอการยืนยัน",
  [BookingStatus.Absent]: "ขาด",
  [BookingStatus.Attended]: "เข้าร่วมแล้ว",
};
export function formatReservationStatus(status: BookingStatus) {
  return reservationStatusMapper[status];
}

const bookingStatusColorMapper = {
  [BookingStatus.Booked]: "success.main",
  [BookingStatus.Waiting]: "warning.main",
  [BookingStatus.Cancelled]: "text.disabled",
  [BookingStatus.Pending]: "warning.main",
  [BookingStatus.Absent]: "error.main",
  [BookingStatus.Attended]: "success.main",
};
export function formatBookingStatusColor(status: BookingStatus) {
  return bookingStatusColorMapper[status];
}

const participantStatusColorMapper = {
  [BookingStatus.Booked]: "info.main",
  [BookingStatus.Waiting]: "warning.main",
  [BookingStatus.Cancelled]: "text.disabled",
  [BookingStatus.Pending]: "warning.main",
  [BookingStatus.Absent]: "error.main",
  [BookingStatus.Attended]: "success.main",
};
export function formatParticipantStatusColor(status: BookingStatus) {
  return participantStatusColorMapper[status];
}

const trainerContractMapper = {
  [TrainerContract.Employee]: "ประจำ",
  [TrainerContract.Freelance]: "ฟรีแลนซ์",
};
export function formatTrainerContract(contract: TrainerContract) {
  return trainerContractMapper[contract];
}

const dayMapper = {
  [Day.Monday]: "จันทร์",
  [Day.Tuesday]: "อังคาร",
  [Day.Wednesday]: "พุธ",
  [Day.Thursday]: "พฤหัสบดี",
  [Day.Friday]: "ศุกร์",
  [Day.Saturday]: "เสาร์",
  [Day.Sunday]: "อาทิตย์",
};
const dayStringMapper = {
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
  sun: "Sunday",
};
const dayWeight = Object.keys(dayMapper);
export function formatDay(day: Day) {
  return dayMapper[day];
}
export function formatDayString(day: DayString) {
  return dayStringMapper[day] as Day;
}
export function sortDay(d1: Day, d2: Day) {
  return dayWeight.indexOf(d1) - dayWeight.indexOf(d2);
}

const genderMap = {
  [Gender.Male]: "ชาย",
  [Gender.Female]: "หญิง",
  [Gender.Unspecified]: "ไม่ระบุ",
};
export function formatGender(gender: Gender) {
  return genderMap[gender];
}

function formatDuration(duration: Duration): string {
  return duration.reconfigure({ locale: "th-TH" }).toHuman();
}

export function formatDurationISO(iso: string): string {
  return formatDuration(fromDurationISO(iso));
}

export function formatDurationObject(object: DurationObjectUnits): string {
  return formatDuration(fromDurationObject(object));
}

const quotaUnitMapper = {
  [QuotaUnit.times]: "ครั้ง",
  [QuotaUnit.hour]: "ชั่วโมง",
};
export function formatQuotaUnit(unit: QuotaUnit) {
  return quotaUnitMapper[unit];
}

const bloodGroupMapper = {
  [BloodGroup.O]: "O",
  [BloodGroup.A]: "A",
  [BloodGroup.B]: "B",
  [BloodGroup.AB]: "AB",
  [BloodGroup.Unspecified]: "ไม่ระบุ",
};
export function formatBloodGroup(bloodGroup: BloodGroup) {
  return bloodGroupMapper[bloodGroup];
}

const personalTaxTypeMapper = {
  [PersonalTax.NormalPerson]: "บุคคลธรรมดา",
  [PersonalTax.JuristicPerson]: "นิติบุคคล",
};
export function formatPersonalTaxType(taxType: PersonalTax) {
  return personalTaxTypeMapper[taxType];
}

const maritalStatusMapper = {
  [MaritalStatus.Single]: "โสด",
  [MaritalStatus.Marry]: "สมรส",
  [MaritalStatus.Unspecified]: "ไม่ระบุ",
  [MaritalStatus.Divorce]: "หย่า",
  [MaritalStatus.Widow]: "หม้าย",
};
export function formatMaritalStatus(status: MaritalStatus) {
  return maritalStatusMapper[status];
}

const memberStatusMapper = {
  [MemberStatus.Active]: "ปกติ",
  [MemberStatus.Expired]: "หมดอายุ",
  [MemberStatus.Ban]: "ถูกระงับ",
};
export function formatMemberStatus(status: MemberStatus) {
  return memberStatusMapper[status];
}

const memberStatusColorMapper = {
  [MemberStatus.Active]: "info.main",
  [MemberStatus.Expired]: "text.disabled",
  [MemberStatus.Ban]: "error.main",
};
export function formatMemberStatusColor(status: MemberStatus) {
  return memberStatusColorMapper[status];
}

export function formatProfile(profile: Profile) {
  const { firstName, lastName, nickname } = profile;
  return `${firstName} ${lastName} (${nickname})`;
}

export function formatProfiles(items: Pick<Staff | Member, "profile">[]) {
  return items.map(({ profile }) => formatProfile(profile)).join(",");
}

export function formatProfileWithCode(profile: Profile, code?: string) {
  return `${code || ""} ${formatProfile(profile)}`;
}

export function formatName({
  firstName,
  lastName,
  nickname,
}: Pick<Profile, "firstName" | "lastName" | "nickname">) {
  return `${firstName} ${lastName} (${nickname})`;
}

export function formatHeight(height: number) {
  return `${height} ซม.`;
}

export function formatWeight(weight: number) {
  return `${weight} กก.`;
}

export function formatPackageDuration(duration: number) {
  return `${duration} เดือน`;
}

const purchaseTypeMapper: Record<PurchaseType, string> = {
  [PurchaseType.Membership]: "แพ็กเกจสมาชิก",
  [PurchaseType.ProductPT]: "แพ็กเกจเทรนเนอร์",
};

export function formatPurchaseType(type: PurchaseType) {
  return purchaseTypeMapper[type];
}

const durationUnitMapper: Record<DurationUnit, string> = {
  years: "ปี",
  months: "เดือน",
  days: "วัน",
};

export function formatDurationUnit(unit: string) {
  return durationUnitMapper[unit as DurationUnit];
}

const fitnessTypeMapper = {
  [FitnessType.Company]: "บริษัท",
  [FitnessType.LimitedPartnership]: "ห้างหุ้นส่วนจำกัด",
  [FitnessType.NaturalPerson]: "บุคคลธรรมดา/ฟรีแลนซ์",
};
export function formatFitnessType(type: FitnessType) {
  return fitnessTypeMapper[type];
}

export function formatFitnessVatRegistration(isVatRegistration: boolean) {
  return isVatRegistration
    ? "จดภาษีมูลค่าเพิ่มแล้ว"
    : "ยังไม่เข้าระบบภาษีมูลค่าเพิ่ม";
}

const productTypeMapper = {
  [ProductType.Only]: "เฉพาะสาขานี้",
  [ProductType.Some]: "บางสาขา",
  [ProductType.Every]: "ทุกสาขา",
};
export function formatProductType(type: ProductType) {
  return productTypeMapper[type];
}

const customerStatusMapper = {
  [CustomerStatus.Lead]: "สนใจ",
  [CustomerStatus.Prospect]: "มีโอกาสซื้อ",
  [CustomerStatus.Member]: "สมาชิก",
  [CustomerStatus.Close]: "ปฏิเสธการขาย",
  [CustomerStatus.Unqualified]: "ไม่ตรงเป้าหมาย",
};
export function formatCustomerStatus(status: CustomerStatus) {
  return customerStatusMapper[status];
}

const customerStatusColorMapper = {
  [CustomerStatus.Lead]: "warning.main",
  [CustomerStatus.Prospect]: "info.main",
  [CustomerStatus.Member]: "success.main",
  [CustomerStatus.Close]: "grey.500",
  [CustomerStatus.Unqualified]: "#7E57C2",
};
export function formatCustomerStatusColor(status: CustomerStatus) {
  return customerStatusColorMapper[status];
}

export function formatCustomerStatusStamp({
  createdAt,
  status,
}: CustomerStatusStamp) {
  return `${formatDate(createdAt)} (${formatCustomerStatus(status)})`;
}

const opportunityStatusMapper = {
  [OpportunityStatus.Open]: "เสนอการขาย",
  [OpportunityStatus.CloseWon]: "ขายสำเร็จ",
  [OpportunityStatus.CloseLose]: "ขายไม่สำเร็จ",
};
export function formatOpportunityStatus(status: OpportunityStatus) {
  return opportunityStatusMapper[status];
}

const opportunityStatusColorMapper = {
  [OpportunityStatus.Open]: "#7E57C2",
  [OpportunityStatus.CloseWon]: "#26A69A",
  [OpportunityStatus.CloseLose]: colors.red[500],
};
export function formatOpportunityStatusColor(status: OpportunityStatus) {
  return opportunityStatusColorMapper[status];
}

const receiptStatusColorMapper = {
  [ReceiptStatus.Approve]: "success.main",
  [ReceiptStatus.Cancelled]: "text.disabled",
};
export function formatReceiptStatusColor(status: ReceiptStatus) {
  return receiptStatusColorMapper[status];
}

const receiptStatusMapper = {
  [ReceiptStatus.Approve]: "อนุมัติ",
  [ReceiptStatus.Cancelled]: "ยกเลิก",
};
export function formatReceiptStatus(status: ReceiptStatus) {
  return receiptStatusMapper[status];
}

export function formatProductStatusColor(status: boolean) {
  return status ? "success.main" : "text.disabled";
}

export function formatProductStatus(status: boolean) {
  return status ? "เปิดใช้งาน" : "ปิดใช้งาน";
}

const discountTypeMapper = {
  [DiscountType.Percentage]: "เปอร์เซ็นต์ %",
  [DiscountType.Bath]: "จำนวนเต็ม",
};
export function formatDiscountType(type: DiscountType) {
  return discountTypeMapper[type];
}

const facilityTypeMapper = {
  [FacilityType.Pool]: "สระว่ายน้ำ",
  [FacilityType.Court]: "สนามกีฬา",
  [FacilityType.Equipment]: "อุปกรณ์",
  [FacilityType.Sauna]: "ห้องซาวน่า",
  [FacilityType.Cliff]: "หน้าผาจำลอง",
  [FacilityType.MeetingRoom]: "ห้องประชุม",
  [FacilityType.NapRoom]: "ห้องพักผ่อน",
  [FacilityType.Other]: "อื่นๆ",
};
export function formatFacilityType(type: FacilityType) {
  return facilityTypeMapper[type];
}

const facilityBookingStatusMapper = {
  [FacilityBookingStatus.Approved]: "ยืนยันแล้ว",
  [FacilityBookingStatus.Pending]: "รอการยืนยัน",
  [FacilityBookingStatus.Rejected]: "ไม่อนุมัติ",
  [FacilityBookingStatus.Cancelled]: "ยกเลิก",
};
export function formatFacilityBookingStatus(status: FacilityBookingStatus) {
  return facilityBookingStatusMapper[status];
}

const facilityBookingStatusColorMapper = {
  [FacilityBookingStatus.Approved]: "success.main",
  [FacilityBookingStatus.Pending]: "warning.main",
  [FacilityBookingStatus.Rejected]: "error.main",
  [FacilityBookingStatus.Cancelled]: "text.secondary",
};
export function formatFacilityBookingStatusColor(
  status: FacilityBookingStatus
) {
  return facilityBookingStatusColorMapper[status];
}

const trainingGoalMapper = {
  [TrainingGoal.BodyComposition]: "Body composition",
  [TrainingGoal.GeneralHealth]: "General health",
  [TrainingGoal.SkillRelated]: "Skill related",
  [TrainingGoal.SportPerformance]: "Sport performance",
  [TrainingGoal.Other]: "อื่นๆ",
};
export function formatTrainingGoal(goal: TrainingGoal) {
  return trainingGoalMapper[goal];
}

const trainingStatusMapper = {
  [TrainingStatus.InProgress]: "กำลังเทรน",
  [TrainingStatus.Success]: "สำเร็จ",
  [TrainingStatus.Failed]: "ไม่สำเร็จ",
};
export function formatTrainingStatus(status: TrainingStatus) {
  return trainingStatusMapper[status];
}

const trainingStatusColorMapper = {
  [TrainingStatus.InProgress]: "info.main",
  [TrainingStatus.Success]: "success.main",
  [TrainingStatus.Failed]: "text.disabled",
};
export function formatTrainingStatusColor(status: TrainingStatus) {
  return trainingStatusColorMapper[status];
}

const trainingStatusPieChartColorMapper = {
  [TrainingStatus.InProgress]: colors.lightBlue[500],
  [TrainingStatus.Success]: colors.green[500],
  [TrainingStatus.Failed]: colors.red[500],
};
export function formatTrainingStatusPieChartColorMapper(
  status: TrainingStatus
) {
  return trainingStatusPieChartColorMapper[status];
}

const permissionGroupTypeMapper = {
  [PermissionGroupType.member]: "สมาชิก",
  [PermissionGroupType.staff]: "พนักงาน",
};
export function formatPermissionGroupType(type: PermissionGroupType) {
  return permissionGroupTypeMapper[type];
}

const saleByCategoryMapper = {
  [ProductCategory.Membership]: "Membership",
  [ProductCategory.PersonalTraining]: "Personal Trainer",
  [ProductCategory.Others]: "Product",
};
export function formatSaleByCategory(category: ProductCategory) {
  return saleByCategoryMapper[category];
}

const saleByCategoryColorMapper = {
  [ProductCategory.Membership]: "#673AB7",
  [ProductCategory.PersonalTraining]: "#FF9800",
  [ProductCategory.Others]: "#8BC34A",
};
export function formatSaleByCategoryColor(category: ProductCategory) {
  return saleByCategoryColorMapper[category];
}
