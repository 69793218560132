import { configs } from "@/configs";
import { api } from "@/client/api";
import {
  parseDateTimeFromPrisma,
  parseISOToPrisma,
  parseURLSearchParams,
} from "@/utils";
import { BookingStatus } from "@/models";

import type { Class, Schedule, Booking, Participant } from "@/models";
import type { DateTime } from "luxon";

type GetClasses = {
  data: Class[];
  total: number;
};

type PublishClassInput = {
  id: string;
  isPublished: boolean;
};

type UpdateClassInput = Omit<
  Class,
  "branches" | "numberOfSchedules" | "categories"
> & {
  categoryIds: number[];
};

type CreateClassInput = Omit<UpdateClassInput, "id">;

type GetClassSchedules = {
  data: Schedule[];
  total: number;
};

export type CreateClassScheduleInput = Omit<
  Schedule,
  | "id"
  | "scheduleTrainers"
  | "class"
  | "numberOfBookings"
  | "bookings"
  | "branch"
> & { classId: string; staffIds: number[] };

export type CreateClassBookingInput = {
  classId: string;
  scheduleId: string;
  memberId: number;
};

export type UpdateClassScheduleInput = CreateClassScheduleInput & {
  scheduleId: string;
};

type CancelClassScheduleInput = {
  classId: string;
  scheduleId: string;
};

type RemoveClassScheduleInput = {
  classId: string;
  scheduleId: string;
};

type GetClassBookingsInput = {
  params?: string;
  classId: string;
  scheduleId: string;
};

type GetClassBookings = {
  data: Booking[];
  total: number;
};

type CancelClassBookingInput = {
  classId: string;
  scheduleId: string;
  bookingId: string;
};

type GetClassParticipantsInput = {
  params?: string;
  scheduleId: string;
};

type GetClassParticipants = {
  data: Participant[];
  total: number;
};

type UpdateClassParticipantInput = {
  bookingId: string;
  status: BookingStatus.Attended | BookingStatus.Absent;
};

type GetAllClassSchedulesInput = {
  start?: DateTime;
  end?: DateTime;
};

export async function getClasses(params = ""): Promise<GetClasses> {
  const response = await api.get(`${configs.apiUrl}/classes?${params}`);
  return parseDateTimeFromPrisma(response.data) as GetClasses;
}

export async function getClass(id: string): Promise<Class> {
  const response = await api.get(`${configs.apiUrl}/classes/${id}`);
  return parseDateTimeFromPrisma(response.data) as Class;
}

export async function createClass(data: CreateClassInput): Promise<Class> {
  const response = await api.post(`${configs.apiUrl}/classes`, data);

  return parseDateTimeFromPrisma(response.data) as Class;
}

export async function updateClass({
  id,
  ...data
}: UpdateClassInput): Promise<Class> {
  const response = await api.patch(`${configs.apiUrl}/classes/${id}`, data);

  return parseDateTimeFromPrisma(response.data) as Class;
}

export async function publishClass(input: PublishClassInput): Promise<Class> {
  const { id, isPublished } = input;
  const response = await api.patch(`${configs.apiUrl}/classes/${id}/publish`, {
    isPublished,
  });

  return parseDateTimeFromPrisma(response.data) as Class;
}

export async function removeClass(id: string): Promise<Class> {
  const response = await api.delete(`${configs.apiUrl}/classes/${id}`);
  return parseDateTimeFromPrisma(response.data) as Class;
}

export async function getClassSchedules(
  params = "",
  classId: string
): Promise<GetClassSchedules> {
  const response = await api.get(
    `${configs.apiUrl}/classes/${classId}/schedules?${params}`
  );
  return parseDateTimeFromPrisma(response.data) as GetClassSchedules;
}

export async function getClassSchedule(
  classId: string,
  id: string
): Promise<Schedule> {
  const response = await api.get(
    `${configs.apiUrl}/classes/${classId}/schedules/${id}`
  );
  return parseDateTimeFromPrisma(response.data) as Schedule;
}

export async function getAllClassSchedules({
  start,
  end,
}: GetAllClassSchedulesInput) {
  const response = await api.get(
    `${configs.apiUrl}/classes/schedules?${parseURLSearchParams({
      start: start?.toISO() || "",
      end: end?.toISO() || "",
    })}`
  );

  return parseDateTimeFromPrisma(response.data) as Schedule[];
}

export async function createClassSchedule({
  classId,
  ...data
}: CreateClassScheduleInput): Promise<Schedule> {
  const response = await api.post(
    `${configs.apiUrl}/classes/${classId}/schedules`,
    parseISOToPrisma(data)
  );

  return parseDateTimeFromPrisma(response.data) as Schedule;
}

export async function updateClassSchedule({
  classId,
  scheduleId,
  ...data
}: UpdateClassScheduleInput): Promise<Schedule> {
  const response = await api.patch(
    `${configs.apiUrl}/classes/${classId}/schedules/${scheduleId}`,
    parseISOToPrisma(data)
  );

  return parseDateTimeFromPrisma(response.data) as Schedule;
}

export async function removeClassSchedule({
  classId,
  scheduleId,
}: RemoveClassScheduleInput): Promise<void> {
  await api.delete(
    `${configs.apiUrl}/classes/${classId}/schedules/${scheduleId}`
  );
}

export async function cancelClassSchedule({
  classId,
  scheduleId,
}: CancelClassScheduleInput): Promise<void> {
  await api.patch(
    `${configs.apiUrl}/classes/${classId}/schedules/${scheduleId}/cancel`
  );
}

export async function getClassBookings({
  classId,
  scheduleId,
  params = "",
}: GetClassBookingsInput): Promise<GetClassBookings> {
  const response = await api.get(
    `${configs.apiUrl}/classes/${classId}/schedules/${scheduleId}/bookings?${params}`
  );
  return parseDateTimeFromPrisma(response.data) as GetClassBookings;
}

export async function getClassParticipants({
  scheduleId,
  params = "",
}: GetClassParticipantsInput): Promise<GetClassParticipants> {
  const response = await api.get(
    `${configs.apiUrl}/classes/schedules/${scheduleId}/participants?${params}`
  );
  return parseDateTimeFromPrisma(response.data) as GetClassParticipants;
}

export async function createClassBooking({
  classId,
  scheduleId,
  memberId,
}: CreateClassBookingInput): Promise<Booking> {
  const response = await api.post(
    `${configs.apiUrl}/classes/${classId}/schedules/${scheduleId}/bookings`,
    { membersId: memberId }
  );

  return parseDateTimeFromPrisma(response.data) as Booking;
}

export async function cancelClassBooking({
  classId,
  scheduleId,
  bookingId,
}: CancelClassBookingInput): Promise<void> {
  await api.patch(
    `${configs.apiUrl}/classes/${classId}/schedules/${scheduleId}/bookings/${bookingId}/cancel`
  );
}

export async function updateClassParticipantStatus({
  bookingId,
  status,
}: UpdateClassParticipantInput): Promise<void> {
  await api.patch(
    `${configs.apiUrl}/classes/participants/${bookingId}/status`,
    { status }
  );
}
