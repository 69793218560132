import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";

import { DataTableBody } from "@/components/DataTableBody";
import { useBankFee } from "@/hooks/useBankFee";
import { getDocumentTitle } from "@/utils";

import { BankFeeTableRow, BankFeeTableRowHeader } from "./BankFeeTableRow";
import { AddBankFeeDialog } from "./AddBankFeeDialog";
import { EditBankFeeDialog } from "./EditBankFeeDialog";
import { RemoveBankFeeDialog } from "./RemoveBankFeeDialog";

const QUERY_KEY = "getBankFees";

export function BankFeeListPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: bankFees, isFetching } = useBankFee(QUERY_KEY);

  const data = bankFees ?? [];

  function add() {
    searchParams.set("dialog", "bank-fee-add");
    setSearchParams(searchParams);
  }

  const dialog = searchParams.get("dialog");
  const id = searchParams.get("id") ?? "";

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("id");
    setSearchParams(searchParams, { replace: true });
  }

  const addDialog = {
    open: dialog === "bank-fee-add",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  const editDialog = {
    open: typeof data !== "undefined" && dialog === "bank-fee-edit",
    id: id.toString(),
    onClose: onCloseDialog,
    data: data.find((item) => item.id.toString() === id),
    fetchKeys: [QUERY_KEY],
  };

  const removeDialog = {
    open: dialog === "bank-fee-remove",
    id,
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("ค่าธรรมเนียม")}</title>
      </Helmet>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={5}
          >
            <Typography typography="h6">
              ค่าธรรมเนียมของธนาคาร/สภาบันการเงิน
            </Typography>
            <Button variant="contained" onClick={add}>
              เพิ่มค่าธรรมเนียม
            </Button>
          </Stack>
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <BankFeeTableRowHeader />
              </TableHead>
              <DataTableBody loading={isFetching} data={data}>
                {data.map((item) => (
                  <BankFeeTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <AddBankFeeDialog {...addDialog} />
      <EditBankFeeDialog {...editDialog} />
      <RemoveBankFeeDialog {...removeDialog} />
    </Box>
  );
}
